import React from "react";

function SuspenseLoader() {
  return (
    <>
      <section className="loader-rjn">
        <span className="loader-72"> </span>
      </section>
    </>
  );
}

export default SuspenseLoader;
