export const REDUCER_PATHS = {
  AUTH: "auth",
  TOKEN: "token",
  USERS: "users",
  MASTER: "master",
  PROFILE: "profile",
  SETTINGS: "settings",
  DASHBOARD: "dashboard",
  FILTER: "filter",
  SUPPORT: "support",
  ORDER: "order",
  PHRASE_MODERATION: "phraseModeration",
  TICKETS: "tickets",
};

export const REDUCER_TAGS = {
  POSTS: "Posts",
  PROFILE: "Profile",
  SETTINGS: "settings",
  USERS: "users",
};
