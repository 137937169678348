import { createApi } from "@reduxjs/toolkit/query/react";

import apiConfig from "configs/apiConfig";
import { REDUCER_PATHS } from "store/config";
// import { transformResponseHandler } from "utils/transformResponseHandler";

import { customFetchBase } from ".";
//import { json } from "react-router-dom";

const orderApi = createApi({
  reducerPath: REDUCER_PATHS.ORDER,
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    orderListing: builder.mutation({
      query: (data) => ({
        url: apiConfig.ORDER,
        body: data,
        method: "POST",
      }),
    }),
    triggerOrderDetails: builder.query({
      query: (data) => ({
        url: `${apiConfig.ORDER_DETAIL}?orderId=${data.orderId}`,
        method: "GET",
      }),
    }),
    orderDetailsDownload: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.ORDER_DETAILS_DOWNLOAD}/${data.type}`,
        body: data,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useOrderListingMutation,
  useLazyTriggerOrderDetailsQuery,
  useOrderDetailsDownloadMutation,
} = orderApi;

export default orderApi;
