import { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";

/** ***************** 
  @purpose : Used For to get background blur effect when any modal is open
  @Parameter : {data,reset}
  @Author : INIC
  ***************** */
export const useModalBlurEffect = () => {
  const modals = useSelector((state) => state.ui.modals, shallowEqual);

  const showBlur = useMemo(
    () => Object.keys(modals).find((key) => modals[key]),
    [modals]
  );

  return { showBlur };
};
