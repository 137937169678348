export const fixedCacheKeys = {
  LOGIN: "user-login-post",
  SEND_OTP: "user-otp-post",
  VERIFY_OTP: "user-verify-post",
  LOGOUT: "user-verify-get",
  FORGOT_PASSWORD: "user-forgotPassword-post",
  RESET_PASSWORD: "user-resetPassword-post",
  EDIT_PROFILE: "user-profileData-Post",
  EDIT_COMMISSION: "commission-commissionData-patch",
  GET_COMMISSION: "commission-getCommissionData-get",
  FILE_UPLOAD: "user-File-post",
  CHANGE_PASSWORD: "user-changePassword-post",
  DELETE_PROFILE: "user-deleteProfile-delete",
  ADD_META_DATA: "user-addMetaData-post",
  EDIT_META_DATA: "user-editMetaDta-put",
  EDIT_SITE_SETTING_DATA: "user-editSiteSettingData-put",
  EDIT_ANALYTICAL_DATA: "user-editAnalyticalData-put",
  ADD_SITE_DATA: "user-addSiteData-Post",
  ADD_ANALYTICAL_DATA: "user-addAnalyticalData-post",
  ADD_MAINTENANCE_DATA: "user-editMaintenanceData-post",
  EDIT_MAINTENANCE_DATA: "user-editMaintenanceData-put",
  DATE_TIME: "user-dateTime-Post",
  EDIT_DATE_TIME: "user-dateTime-put",
  ADD_SOCIAL_MEDIA_SDK: "user-addSocialMediaSdk-post",
  EDIT_SOCIAL_MEDIA_SDK: "user-editSocialMediaSdk-put",
  ADD_SOCIAL_MEDIA_LINK: "user-addSocialMediaLink-post",
  EDIT_SOCIAL_MEDIA_LINK: "user-editSocialMediaLink-put",
  CHANGE_STATUS: "user-changeStatus-post",
  ADD_SMTP_DETAILS: "user-addSmtpDetails-post",
  EDIT_SMTP_DETAILS: "user-editSmtpDetails-put",
  ADD_SMS_DETAILS: "user-addSmsDetails-post",
  EDIT_SMS_DETAILS: "user-editSmsDetails-put",
  NOTIFICATION_STATUS: "setting-notificationStatus-post",
  ADD_PAYMENT_DETAILS: "setting-paymentData-post",
  EDIT_PAYMENT_DETAILS: "setting-paymentData-put",
  TIMEZONE_LIST: "country-timezoneList-post",
  TIMEZONE_DELETE: "country-timeZoneData-delete",
  ADD_TIMEZONE: "country-addTimezone-post",
  TIMEZONE_CHANGE_STATUS: "timezone-changeStatus-post",
  COUNTRY_LIST: "country-list-post",
  COUNTRY_LIST_DELETE: "country-list-delete",
  COUNTRY_LIST_CHANGE_STATUS: "country-list-changeStatus-patch",
  ADD_COUNTRYLIST: "country-addCountryList-post",
  LANGUAGE_MESSAGES: "language-messages-post",
  ADD_LANGUAGE_MESSAGES: "language-addMessages-post",
  LANGUAGE_LABEL: "language-label-post",
  ADD_LANGUAGE_LABEL: "language-addEditLabel-post",
  EDIT_LANGUAGE_LABEL: "language-editLabel-put",
  LANGUAGE_LIST: "country-language-post",
  LANGUAGE_LIST_CHANGE_STATUS: "language-list-changeStatus-patch",
  ADD_LANGUAGELIST: "country-addLanguageList-post",
  ADD_ROLE: "role-addRole-post",
  EDIT_ROLE: "role-editRole-put",
  ROLE_LIST: "role-roleList-post",
  CHANGE_ROLE: "role-changeRole-patch",
  DELETE_ROLE: "role-deleteRole-delete",
  USER_LIST: "role-usersList-get",

  //adminUser
  ADMIN_USER_DATA: "users-adminUsersData-post",
  ADD_ADMIN_USER_DATA: "users-addAdminUsersData-post",
  EDIT_ADMIN_USER_DATA: "users-editAdminUsersData-put",
  DELETE_ADMIN_USER_DATA: "users-deleteAdminUsersData-delete",
  ADMIN_USER_DETAILS_DOWNLOAD: "users-adminusersDownload-get",

  //User data
  USER_DATA: "users-UsersData-get",
  ADD_USER_DATA: "users-addUsersData-post",
  EDIT_USER_DATA: "users-editusersData-put",
  DELETE_USER_DATA: "users-deleteUsersData-delete",
  CHANGE_USER_STATUS: "users-changeUsersStatus-post",
  STATUS_ADMIN_USER_DATA: "users-statusAdminUsersData-post",
  USER_DETAILS_DOWNLOAD: "users-userDetailsDownload-get",
  DELETE_REVIEW_DATA: "review-deleteReviewData-delete",
  EDIT_REVIEW_RATING_DETAIL: "review-editReviewRatingDetail-put",

  //static page
  STATIC_PAGE_LISTING: "static-staticPageListing-post",
  STATIC_PAGE_CHANGE_STATUS: "static-staticPageChangeStatus-post",
  ADD_STATIC_PAGES: "static-addStaticPages-post",
  EDIT_STATIC_PAGES: "static-editStaticPages-post",
  DELETE_STATIC_PAGES: "static-deleteStaticPages-delete",
  STATIC_PAGE_LIST_DOWNLAOD: "static-staticpageDownload-get",

  //filter
  SAVE_FILTER_DATA: "filter-saveFilter-post",
  GET_FILTER_DATA: "filter-getFilter-post",
  DELETE_FILTER_DATA: "filter-deleteFilter-delete",
  SAVE_COLUMN_DATA: "column-saveColumn-post",
  GET_COLUMN_DATA: "column-getColumn-post",
  DELETE_COLUMN_DATA: "column-deleteColumn-delete",

  //support
  SUPPORT_LISTING: "support-getSupportList-get",
  SUPPORT_DELETE: "support-deleteSupport-delete",
  SUPPORT_STATUS: "support-ticketStatus-patch",
  SUPPORT_ADD_DATA: "support-addSupportData-post",
  SUPPORT_EDIT_DATA: "support-editReplyData-put",
  SUPPORT_REPLY_DELETE: "support-deleteReplyData-delete",
  ADD_TICKET_REASON: "support-addTicketReason-post",

  //Phrase Moderation
  PHRASE_DATA: "phrase-phraseData-get",
  ADD_PHRASE_DATA: "phrase-addPhraseData-post",
  EDIT_PHRASE_DATA: "phrase-editPhraseData-post",
  DELETE_PHRASE_DATA: "phrase-deletePhraseData-delete",
  CHANGE_PHRASE_STATUS: "phrase-changePhraseStatus-patch",
  PHRASE_DETAILS: "phrase-phraseDetails-get",

  //tickets Management
  REASONS_DATA: "reason-reasonsData-get",
  ADD_REASON_DATA: "reason-addReasonData-post",
  EDIT_REASON_DATA: "reason-editReasonData-post",
  DELETE_REASON_DATA: "reason-deleteReasonData-delete",
  CHANGE_REASON_STATUS: "reason-changeReasonStatus-patch",
  REASON_DETAILS: "reason-reasonDetails-get",

  //Order
  ORDER_DATA: "order-orderData-post",
  ORDER_DETAILS_DOWNLOAD: "order-orderDetailDownload-post",

  //dashboard
  DASHBOARD_COUNT: "dashboard-dashboardCount-post",
};
