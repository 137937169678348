import * as types from "store/actions/index";

/** ***************** 
@purpose :  Handle login user action to save token
@Parameter : {value, key}
@Author : INIC
******************/
export const loginUserTokenAction = (payload) => ({
  type: types.loginUserKey.toString(),
  payload,
});

/** ***************** 
@purpose :  Handle logout user action to clear token
@Parameter : {value, key}
@Author : INIC
******************/
export const logoutUserTokenAction = () => ({
  type: types.logoutUserKey.toString(),
});
