import SuspenseLoader from "lib/suspenseLoader/SuspenseLoader";
import React, { Suspense } from "react";
import { useRoutes } from "react-router-dom";

import { getRoutes } from "routes/config";

/**
 * Creates all routes using useRoutes hook
 * @return {JSX.Element}
 */
function BaseRoutes() {
  const allRoutes = useRoutes(getRoutes());
  return <Suspense fallback={<SuspenseLoader />}>{allRoutes}</Suspense>;
}

export default BaseRoutes;
