import { createReducer } from "@reduxjs/toolkit";

import { loginUserKey, logoutUserKey } from "store/actions";

/** ***************** 
@purpose : Intital ui reducer data
@Author : INIC
***************** */
const initialState = {
  access_token: "",
  refreshToken: "",
  getStreamToken: "",
  data: {},
};

/** ***************** 
  @purpose : UI Reducer
  @Parameter : {ui_ini_data, action}
  @Author : INIC
  ***************** */
const userTokenReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(loginUserKey.toString(), (state, action) => {
      state.access_token = action.payload?.accessToken || "";
      state.data = action.payload?.data || {};
      state.refreshToken = action.payload?.refreshToken || "";
      state.getStreamToken = action.payload?.getStreamToken || "";
    })
    .addCase(logoutUserKey.toString(), (state) => {
      state.access_token = "";
      state.data = {};
      state.refreshToken = "";
      state.getStreamToken = "";
    });
});

export default userTokenReducer;
