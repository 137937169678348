import { createAction } from "@reduxjs/toolkit";

/** ***************** 
@purpose : All action types
@Author : INIC
***************** */

/**
 * UI Actions
 */
export const setUiKey = createAction("ui/setKey");
export const toggleUiModal = createAction("ui/toggleModal");
export const toggleLoader = createAction("ui/toggleLoader");
export const setUILanguage = createAction("ui/setUILanguage");
export const setUILanguageWithDefault = createAction(
  "ui/setUILanguageWithDefault"
);

/**
 * Auth Actions
 */
export const loginUserKey = createAction("auth/login");
export const logoutUserKey = createAction("auth/logout");

/**
 * Permissions Actions
 */
export const permissionsKey = createAction("permissions/manage");
