import { createApi } from "@reduxjs/toolkit/query/react";

import apiConfig from "configs/apiConfig";
import { REDUCER_PATHS } from "store/config";

import { customFetchBase } from ".";

const dashboardApi = createApi({
  reducerPath: REDUCER_PATHS.DASHBOARD,
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    dashboardCount: builder.mutation({
      query: (data) => ({
        url: apiConfig.GET_DASHBOARD_COUNT,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useDashboardCountMutation } = dashboardApi;

export default dashboardApi;
