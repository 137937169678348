import SuspenseLoader from "lib/suspenseLoader/SuspenseLoader";
import React, { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Navigate, useLocation, useParams } from "react-router-dom";

import { PATHS } from "configs/routeConfig";

// Defined scopes. These scopes are related to crud actions.
// Naming convention as per data from backend.
// Update when any data is changed from backend api
export const AccessScopes = {
  CREATE: "Create",
  EDIT: "Edit",
  VIEW: "View",
  CHANGE: "Change Status",
  DELETE: "Delete",
};

// Permission scopes. these scopes are related to module.
// Names are used as per the backend api role category.
// Add/update/remove any scope as per new module added
// or any module category updated from backedn or any module deleted.

export const PERMISSION_SCOPES = {
  Dashboard: "Dashboard",
  Master: "Master",
  Admin_User: "Admin User",
  User: "User",
  Settings: "Settings",
  CMS_Pages: "CMS Pages",
  Users: "Users",
  Content: "Content",
  Profile: "Profile",
  Support: "Support",
  Common_Pages: "Common Pages",
  Role: "Role",
};

// Route scope. map url route with permission scopes
// Add/update/delete when any new route is added or any route is modified
export const routeScopes = {
  // Dashboard
  [PATHS.DASHBOARD]: { scope: PERMISSION_SCOPES.Dashboard },
  // User
  [`${PATHS.USER}/${PATHS.USER_LIST}`]: {
    scope: PERMISSION_SCOPES.User,
  },
  [`${PATHS.USER}/${PATHS.ADMIN_USER}`]: {
    scope: PERMISSION_SCOPES.Admin_User,
  },

  // Admin user
  [`${PATHS.USER}/${PATHS.ADMIN_USER}`]: {
    scope: PERMISSION_SCOPES.Admin_User,
  },
  // Static pages
  [`${PATHS.CONTENT}/${PATHS.STATIC_PAGES}`]: {
    scope: PERMISSION_SCOPES.CMS_Pages,
  },

  // Master
  [`${PATHS.MASTER_MANAGEMENT}/${PATHS.COUNTRY}`]: {
    scope: PERMISSION_SCOPES.Master,
  },
  [`${PATHS.MASTER_MANAGEMENT}/${PATHS.TIMEZONE}`]: {
    scope: PERMISSION_SCOPES.Master,
  },
  [`${PATHS.MASTER_MANAGEMENT}/${PATHS.LANGUAGE}`]: {
    scope: PERMISSION_SCOPES.Master,
  },

  // Support
  [PATHS.SUPPORT]: { scope: PERMISSION_SCOPES.Support },
  // Settings
  [`${PATHS.SETTINGS}/${PATHS.GENERAL_SETTINGS}`]: {
    scope: PERMISSION_SCOPES.Settings,
  },
  [`${PATHS.SETTINGS}/${PATHS.SOCIAL_MEDIA}`]: {
    scope: PERMISSION_SCOPES.Settings,
  },
  [`${PATHS.SETTINGS}/${PATHS.PAYMENT_GATEWAY}`]: {
    scope: PERMISSION_SCOPES.Settings,
  },
  [`${PATHS.SETTINGS}/${PATHS.SMTP_SMS_DETAILS}`]: {
    scope: PERMISSION_SCOPES.Settings,
  },
  [`${PATHS.SETTINGS}/${PATHS.EMAIL_NOTIFICATIONS}`]: {
    scope: PERMISSION_SCOPES.Settings,
  },
  [`${PATHS.SETTINGS}/${PATHS.LANGUAGE_MESSAGES}`]: {
    scope: PERMISSION_SCOPES.Settings,
  },
  [`${PATHS.SETTINGS}/${PATHS.LANGAUGE_STATIC_LANG}`]: {
    scope: PERMISSION_SCOPES.Settings,
  },
  // ROle
  [PATHS.ROLE]: {
    scope: PERMISSION_SCOPES.Role,
  },
  // Profile
  [PATHS.MY_PROFILE]: {
    scope: PERMISSION_SCOPES.Profile,
  },
  [PATHS.EDIT_MY_PROFILE]: {
    scope: PERMISSION_SCOPES.Profile,
  },
};

/******************* 
  @purpose :Used for checking aside menu link permission
  @Author : INIC
  ******************/
export function ModulePermissionGate({ children, scope }) {
  const { modules, slug } = useSelector(
    (state) => state.permissions,
    shallowEqual
  );

  const hasPermission = useMemo(
    () =>
      slug === "super_admin"
        ? true
        : !!modules?.find(
            (module) => scope === true || module.category === scope
          ),
    [modules]
  );

  if (hasPermission) {
    return children;
  }
  return null;
}

export const formatUrlPathScopes = (pathname, params) => {
  // remove any params from url
  const pathWithoutParams = Object.values(params).reduce(
    (path, param) => path.replace("/" + param, ""),
    pathname
  );

  // get crud scope from url (i.e view/create/edit)
  const pathScope = Object.values(AccessScopes).find(
    (scope) => pathWithoutParams.indexOf(scope.toLowerCase()) != -1
  );

  // get url module path
  const pathWithoutScope = pathScope
    ? pathWithoutParams.replace("/" + pathScope.toLowerCase(), "")
    : pathWithoutParams;

  return { pathWithoutParams, pathScope, pathWithoutScope };
};

/******************* 
  @purpose :Used for checking route permission.
            Checks any url entered in browser if it has permission
  @Author : INIC
  ******************/
export function RoutePermissionGate({ children }) {
  const { modules, isModuleLoading, slug } = useSelector(
    (state) => state.permissions,
    shallowEqual
  );
  const { pathname } = useLocation();
  const params = useParams();

  const {
    hasModulePermission,
    pathScope,
    pathWithoutScope,
    hasScopePermission,
  } = useMemo(() => {
    // return without checking if user is super admin or api is loading
    if (isModuleLoading || slug === "super_admin") {
      return {};
    }

    const { pathWithoutParams, pathScope, pathWithoutScope } =
      formatUrlPathScopes(pathname, params);

    const currentModule = modules?.find(
      (module) => module.category === routeScopes[pathWithoutScope]?.scope
    );

    let currentScope = false;
    if (pathScope) {
      currentScope = currentModule?.permissions?.find(
        (mod) => mod.permission === `${pathScope} ${currentModule.category}`
      )?.isSelected;
    }

    return {
      hasModulePermission: !!currentModule,
      hasScopePermission: !!currentScope,
      pathWithoutScope,
      pathWithoutParams,
      pathScope,
    };
  }, [modules, pathname]);

  if (isModuleLoading) {
    return <SuspenseLoader />;
  }

  // if role is super admin return children without checking
  if (slug === "super_admin") {
    return children;
  }

  // if user doesnot have permission naviagte to dashboard
  if (!hasModulePermission && pathWithoutScope !== PATHS.DASHBOARD) {
    return <Navigate to={PATHS.DASHBOARD} />;
  }

  // if user have module permission but doesnot have crud operation permission
  // then navigate to back to module
  if (pathScope && hasModulePermission && !hasScopePermission) {
    return <Navigate to={pathWithoutScope} replace />;
  }

  return children;
}

export function ScopePermissionGate({ children, scope, module }) {
  const { isModuleLoading, slug, formatedModulesData } = useSelector(
    (state) => state.permissions,
    shallowEqual
  );

  const hasPermission = useMemo(
    () =>
      slug !== "super_admin" &&
      !!(
        formatedModulesData?.[module]?.[`${scope} ${module}`] ||
        formatedModulesData?.[module]?.allScope
      ),
    []
  );

  if (isModuleLoading) {
    return <></>;
  }

  // if role is super admin return children without checking
  if (slug === "super_admin") {
    return children;
  }

  if (!hasPermission) {
    return <></>;
  }

  return children;
}
