import { PERMISSION_SCOPES } from "components/layout/AccessControl";
import { useDispatch } from "react-redux";

import { perimssionsActions } from "store/actions/permissions";

export const usePermissionsHook = () => {
  const dispatch = useDispatch();
  const getFormatModuleData = (modules) => {
    let modulesData = [],
      formatedData = {};
    let showUsers = false,
      showContent = false;

    for (let module of modules) {
      let hasPermission = module?.permissions?.find(
        (permission) => permission.permission === `View ${module.category}`
      )?.isSelected;
      if (
        hasPermission &&
        (module.category === PERMISSION_SCOPES.User ||
          module.category === PERMISSION_SCOPES.Admin_User)
      ) {
        showUsers = true;
      }
      if (hasPermission && module.category === PERMISSION_SCOPES.CMS_Pages) {
        showContent = true;
      }
      if (hasPermission) {
        formatedData[module?.category] = {};
        for (let permission of module?.permissions) {
          formatedData[module.category] = {
            ...formatedData[module.category],
            [permission?.permission]: permission?.isSelected,
          };
        }
        modulesData.push(module);
      }
    }
    if (showUsers) {
      modulesData.push({ category: PERMISSION_SCOPES.Users });
      formatedData[PERMISSION_SCOPES.Users] = { allScope: true };
    }
    if (showContent) {
      modulesData.push({ category: PERMISSION_SCOPES.Content });
      formatedData[PERMISSION_SCOPES.Content] = { allScope: true };
    }
    modulesData.push({ category: PERMISSION_SCOPES.Dashboard });
    modulesData.push({ category: PERMISSION_SCOPES.Support });
    modulesData.push({ category: PERMISSION_SCOPES.Common_Pages });
    formatedData[PERMISSION_SCOPES.Dashboard] = { allScope: true };
    formatedData[PERMISSION_SCOPES.Support] = { allScope: true };
    formatedData[PERMISSION_SCOPES.Common_Pages] = { allScope: true };
    return { modulesData, formatedData };
  };
  const setModulePermissions = (roleDetailsData) => {
    if (roleDetailsData?.categoryPermissions) {
      const { formatedData, modulesData } = getFormatModuleData(
        roleDetailsData?.categoryPermissions
      );
      if (roleDetailsData?.slug === "super_admin") {
        modulesData.push({ category: PERMISSION_SCOPES.Role });
        formatedData[PERMISSION_SCOPES.Role] = { allScope: true };
      }

      dispatch(
        perimssionsActions({
          modules: modulesData,
          formatedModulesData: formatedData,
          slug: roleDetailsData?.slug,
          _id: roleDetailsData?._id,
          role: roleDetailsData?.role,
          isModuleLoading: false,
        })
      );
    }
  };

  return { setModulePermissions };
};
