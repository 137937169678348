import { createApi } from "@reduxjs/toolkit/query/react";

import apiConfig from "configs/apiConfig";
import { REDUCER_PATHS } from "store/config";
import { transformResponseHandler } from "utils/transformResponseHandler";

import { customFetchBase } from ".";

const masterApi = createApi({
  reducerPath: REDUCER_PATHS.MASTER,
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getTimeZone: builder.query({
      query: () => ({
        url: apiConfig.TIMEZONE,
        method: "GET",
      }),
      providesTags: ["Timezone"],
    }),

    timezoneList: builder.mutation({
      query: (data) => ({
        url: apiConfig.TIMEZONE_LIST,
        method: "POST",
        body: data,
      }),
    }),
    timezoneDelete: builder.mutation({
      query: (data) => ({
        url: apiConfig.TIMEZONE,
        method: "DELETE",
        body: data,
      }),
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    addTimezone: builder.mutation({
      query: (data) => ({
        url: apiConfig.TIMEZONE,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Timezone"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    getCountry: builder.query({
      query: () => ({
        url: apiConfig.COUNTRY,
        method: "GET",
      }),
      providesTags: ["Country"],
    }),
    timezoneChangeStatus: builder.mutation({
      query: (data) => ({
        url: apiConfig.TIMEZONE,
        body: data,
        method: "PATCH",
      }),
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    // country
    countryList: builder.query({
      query: (data) => ({
        url: `${apiConfig.COUNTRY}?page=${data?.page}&pageSize=${data?.pageSize}&searchText=${data.searchText}&sortColumn=${data.sortColumn}&sortDirection=${data.sortDirection}`,
        method: "GET",
      }),
      providesTags: ["countryListing"],
    }),
    countryListDelete: builder.mutation({
      query: (data) => ({
        url: apiConfig.COUNTRY,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["countryListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    countryListChangeStatus: builder.mutation({
      query: (data) => ({
        url: apiConfig.COUNTRY,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["Currency", "countryListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    addCountryList: builder.mutation({
      query: (data) => ({
        url: apiConfig.COUNTRY,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Currency", "Country", "countryListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    currencyList: builder.query({
      query: () => ({
        url: apiConfig.CURRENCY_LIST,
        method: "GET",
      }),
      providesTags: ["Currency"],
    }),

    // Language
    getLanguage: builder.query({
      query: () => ({
        url: apiConfig.GET_LANGUAGE,
        method: "GET",
      }),
      providesTags: ["Language"],
    }),

    languageList: builder.query({
      query: (data) => ({
        url: `${apiConfig.LANGUAGE}?page=${data.page}&pageSize=${data.pageSize}&searchText=${data.searchText}&sortColumn=${data.sortColumn}&sortDirection=${data.sortDirection}`,
        method: "GET",
      }),
      providesTags: ["languageListing"],
    }),
    languageListChangeStatus: builder.mutation({
      query: (data) => ({
        url: apiConfig.LANGUAGE,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["Language", "languageListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    languageListDelete: builder.mutation({
      query: (data) => ({
        url: apiConfig.LANGUAGE,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["Language", "languageListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    addLanguageList: builder.mutation({
      query: (data) => ({
        url: apiConfig.LANGUAGE,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Language", "languageListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
  }),
});

export const {
  useGetTimeZoneQuery,
  useTimezoneListMutation,
  useTimezoneDeleteMutation,
  useAddTimezoneMutation,
  useGetCountryQuery,
  useTimezoneChangeStatusMutation,
  useLazyCountryListQuery,
  useCountryListDeleteMutation,
  useCountryListChangeStatusMutation,
  useAddCountryListMutation,
  useCurrencyListQuery,
  useGetLanguageQuery,
  useLazyLanguageListQuery,
  useLanguageListChangeStatusMutation,
  useLanguageListDeleteMutation,
  useAddLanguageListMutation,
} = masterApi;

export default masterApi;
