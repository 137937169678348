export const localStorageKeys = {
  ACCESS_TOKEN: "ua_at",
  REFRESH_TOKEN: "ua_rt",
  REMEMBER_ME: "ua_rm",
  EMAIL: "ua_e",
  PASSWORD: "ua_p",
  MASKED_EMAIL: "ua_me",
  MASKED_PHONE: "ua_mp",
  OTP_TOKEN: "ua_o_t",
  IS_EMAIL: "ua_i_e",
  IS_MOBILE: "ua_i_m",
  THEME: "ua_th",
  EXPIRATIONTIME: "ua_ot",
  VERIFY_LOGIN_TOKEN: "ua_vt",
  GET_STREAM_TOKEN: "ua_gst",
};
