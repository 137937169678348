import { createApi } from "@reduxjs/toolkit/query/react";

import apiConfig from "configs/apiConfig";
import { REDUCER_PATHS } from "store/config";
import { transformResponseHandler } from "utils/transformResponseHandler";

import { customFetchBase } from ".";

const ticketsApi = createApi({
  reducerPath: REDUCER_PATHS.TICKETS,
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    ticketReasonsListing: builder.query({
      query: (data) => ({
        url: `${apiConfig.TICKET_REASONS}?page=${data?.page}&pageSize=${data?.pageSize}&searchText=${data?.searchText}&sortColumn=${data?.sortColumn}&sortDirection=${data?.sortDirection}`,
        method: "GET",
      }),
      providesTags: ["ticketReasonsList"],
    }),
    ticketReasonDetail: builder.query({
      query: (data) => ({
        url: `${apiConfig.TICKET_REASON_DETAIL}?reasonId=${data}`,
        method: "GET",
      }),
    }),

    addReason: builder.mutation({
      query: (data) => ({
        url: apiConfig.ADD_REASON,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["ticketReasonsList"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),

    editReason: builder.mutation({
      query: (data) => ({
        url: apiConfig.EDIT_REASON,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["ticketReasonsList"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),

    deleteReason: builder.mutation({
      query: (data) => ({
        url: apiConfig.DELETE_REASON,
        body: data,
        method: "DELETE",
      }),
      invalidatesTags: ["ticketReasonsList"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),

    changeReasonStatus: builder.mutation({
      query: (data) => ({
        url: apiConfig.CHANGE_REASON_STATUS,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["ticketReasonsList"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
  }),
});

export const {
  useLazyTicketReasonsListingQuery,
  useAddReasonMutation,
  useEditReasonMutation,
  useDeleteReasonMutation,
  useChangeReasonStatusMutation,
  useLazyTicketReasonDetailQuery,
} = ticketsApi;

export default ticketsApi;
