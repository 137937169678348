import { createApi } from "@reduxjs/toolkit/query/react";

import apiConfig from "configs/apiConfig";
import { REDUCER_PATHS } from "store/config";
import { transformResponseHandler } from "utils/transformResponseHandler";

import { customFetchBase } from ".";

const userApi = createApi({
  reducerPath: REDUCER_PATHS.USERS,
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    adminManagementListing: builder.query({
      query: (data) => ({
        url: `${apiConfig.LISTING_ADMIN_MANAGEMENT}?page=${data?.page}&pageSize=${data?.pageSize}&searchText=${data?.searchText}&sortColumn=${data?.sortColumn}&sortDirection=${data?.sortDirection}&filter=${data?.filter}`,
        method: "GET",
      }),
    }),
    addAdminUser: builder.mutation({
      query: (data) => ({
        url: apiConfig.ADMIN_MANAGEMENT,
        body: data,
        method: "POST",
      }),
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editAdminUser: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.ADMIN_MANAGEMENT}/${data?.id}`,
        body: data?.postData,
        method: "PUT",
      }),
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    deleteAdminUser: builder.mutation({
      query: (data) => ({
        url: apiConfig.ADMIN_MANAGEMENT,
        body: data,
        method: "DELETE",
      }),
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    statusChangeAdminUser: builder.mutation({
      query: (data) => ({
        url: apiConfig.ADMIN_MANAGEMENT,
        body: data,
        method: "PATCH",
      }),
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    detailsAdminUser: builder.query({
      query: (data) => ({
        url: `${apiConfig.ADMIN_MANAGEMENT}/${data.id}`,
        method: "GET",
      }),
    }),
    userManagementListing: builder.query({
      query: (data) => ({
        url: `${apiConfig.LISTING_USER_MANAGEMENT}?page=${data?.page}&pageSize=${data?.pageSize}&searchText=${data?.searchText}&sortColumn=${data?.sortColumn}&sortDirection=${data?.sortDirection}&languageId=${data?.languageId}&filter=${data?.filter}`,
        method: "GET",
      }),
    }),
    addUser: builder.mutation({
      query: (data) => ({
        url: apiConfig.USER_MANAGEMENT,
        body: data?.postData || data,
        method: "POST",
      }),
      invalidatesTags: ["userDetails"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editUser: builder.mutation({
      query: (data) => ({
        url: apiConfig.USER_MANAGEMENT + "/" + data?.userId,
        body: data?.postData,
        method: "PUT",
      }),
      invalidatesTags: ["userDetails"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    deleteUser: builder.mutation({
      query: (data) => ({
        url: apiConfig.USER_MANAGEMENT,
        body: data,
        method: "DELETE",
      }),
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    changeUserStatus: builder.mutation({
      query: (data) => ({
        url: apiConfig.USER_MANAGEMENT,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["userDetails"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    userDetails: builder.query({
      query: (data) => ({
        url: `${apiConfig.USER_MANAGEMENT}?userId=${data?.userId}`,
        method: "GET",
      }),
      providesTags: ["userDetails"],
    }),
    userDetailsDownload: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.USER_MANAGEMENT_DOWNLOAD}/${data.type}?languageId=${data?.languageId}&filter=${data.filter}&column=${data.column}`,
        method: "GET",
      }),
    }),
    adminUserDetailsDownload: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.ADMIN_USERS_DOWNLOAD}/${data.type}?filter=${data.filter}&column=${data.column}`,
        method: "GET",
      }),
    }),
    deleteReview: builder.mutation({
      query: (data) => ({
        url: apiConfig.DELETE_REVIEW,
        body: data,
        method: "DELETE",
      }),
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    ReviewRatingDetail: builder.query({
      query: (data) => ({
        url: `${apiConfig.REVIEW_RATING_DETAILS}/?userId=${data.userId}&userType=${data.userType}&page=${data.page}&pageSize=${data.pageSize}`,
        method: "GET",
      }),
    }),
    editReviewRatingDetail: builder.mutation({
      query: (data) => ({
        url: apiConfig.EDIT_REVIEW_RATING_DETAILS + "/" + data?.id,
        body: data?.body,
        method: "PUT",
      }),
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    TicketDetail: builder.query({
      query: (data) => ({
        url: `${apiConfig.TICKETS_DETAILS}/${data?.userId}?userType=${data.userType}&page=${data.page}&pageSize=${data.pageSize}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyAdminManagementListingQuery,
  useAddAdminUserMutation,
  useDeleteAdminUserMutation,
  useStatusChangeAdminUserMutation,
  useDetailsAdminUserQuery,
  useLazyUserManagementListingQuery,
  useAddUserMutation,
  useDeleteReviewMutation,
  useDeleteUserMutation,
  useChangeUserStatusMutation,
  useLazyUserDetailsQuery,
  useLazyReviewRatingDetailQuery,
  useLazyTicketDetailQuery,
  useUserDetailsDownloadMutation,
  useAdminUserDetailsDownloadMutation,
  useEditUserMutation,
  useEditAdminUserMutation,
  useEditReviewRatingDetailMutation,
} = userApi;

export default userApi;
