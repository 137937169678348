import {
  //  ModulePermissionGate,
  formatUrlPathScopes,
} from "components/layout/AccessControl";
import React from "react";
import Accordion from "react-bootstrap/Accordion";
// import Badge from "react-bootstrap/Badge";
import Nav from "react-bootstrap/Nav";
import { Link, useParams } from "react-router-dom";

import { MenuList, MenuTypes } from "configs/menuList";

export const generateAsideMenu = (pathname, menuList = MenuList) => {
  return menuList.map((menu) => {
    if (menu.type === MenuTypes.GROUP) {
      return (
        <MenuGroup menu={menu} key={menu.title} pathname={pathname}>
          {generateAsideMenu(pathname, menu.items)}
        </MenuGroup>
      );
    }
    if (menu.type === MenuTypes.COLLAPSE) {
      return (
        //<ModulePermissionGate scope={menu.role} key={menu.title}>
        <li key={menu.title}>
          <MenuCollapseItems menu={menu} key={menu.title} pathname={pathname}>
            {generateAsideMenu(pathname, menu.items)}
          </MenuCollapseItems>
        </li>
        //</ModulePermissionGate>
      );
    }
    if (menu.type === MenuTypes.COLLAPSE_LIST) {
      return (
        //<ModulePermissionGate scope={menu.role} key={menu.title}>
        <Nav.Item key={menu.title}>
          <MenuCollapseItems menu={menu} key={menu.title} pathname={pathname}>
            {generateAsideMenu(pathname, menu.items)}
          </MenuCollapseItems>
        </Nav.Item>
        //</ModulePermissionGate>
      );
    }
    if (menu.type === MenuTypes.ITEM) {
      return (
        //<ModulePermissionGate scope={menu.role} key={menu.title}>
        <li key={menu.title}>
          <MenuItem item={menu} key={menu.title} pathname={pathname} />
        </li>
        //</ModulePermissionGate>
      );
    }
    return null;
  });
};

function MenuGroup({ menu, children }) {
  return (
    <div className="components">
      <div className="components-head">
        <span>{menu.title}</span>
      </div>
      <ul className="list-unstyled main-menu">{children}</ul>
    </div>
  );
}

function MenuCollapseItems({ menu, pathname, children }) {
  return (
    <Accordion
      defaultActiveKey={
        pathname?.indexOf(menu?.eventKey) !== -1 ? menu?.eventKey : false
      }
    >
      <Accordion.Item
        eventKey={menu.eventKey || menu.title}
        // className="accordion-side-toggle"
      >
        <Accordion.Header as="div" className="sidebar-link">
          {/* <Nav.Link className="sidebar-link"> */}
          <span className={`${menu.icon}`}></span>
          <span className="sidebar-link-text text-truncate">{menu.title}</span>
          {/* </Nav.Link> */}
        </Accordion.Header>
        <Accordion.Body>
          <ul className="list-unstyled sub-menu">
            {/* <Nav.Item>{generateAsideMenu(pathname, menu.items)}</Nav.Item> */}
            {children}
          </ul>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

function MenuItem({ item, pathname }) {
  const params = useParams();

  const { pathWithoutScope } = formatUrlPathScopes(pathname, params);

  return (
    <Link
      className={`sidebar-link${
        pathWithoutScope === item.href ? " active" : ""
      } ${item.linkClassName ? item.linkClassName : ""}`}
      to={item.href}
    >
      {item.type === MenuTypes.ITEM && <span className={`${item.icon}`}></span>}
      <span
        className={`sidebar-link-text ${
          item.titleClassName ? item.titleClassName : ""
        } text-truncate`}
      >
        {item.title}
      </span>
      {/* {item.badge && item.badge !== 0 && (
        <Badge bg={item.badgeBg || "primary"} className={item.badgeClassName}>
          {item.badge}
        </Badge>
      )} */}
    </Link>
  );
}
