import { PERMISSION_SCOPES } from "components/layout/AccessControl";

import { PATHS } from "./routeConfig";

export const MenuTypes = {
  GROUP: "group",
  ITEM: "item",
  COLLAPSE: "collapse",
  COLLAPSE_LIST: "collapse-list",
};

export const MenuList = [
  {
    title: "Main",
    type: MenuTypes.GROUP,
    items: [
      {
        title: "Dashboard",
        type: MenuTypes.ITEM,
        role: PERMISSION_SCOPES.Dashboard,
        badge: 4,
        badgeBg: "primary",
        badgeClassName: "badge-numeric",
        href: PATHS.DASHBOARD,
        icon: "inic inic-home",
      },
      {
        title: "Users",
        type: MenuTypes.ITEM,
        badge: "New",
        badgeBg: "secondary",
        badgeClassName: "badge-text",
        href: `${PATHS.USER}/${PATHS.USER_LIST}`,
        icon: "inic inic-person",
      },
      {
        title: "Orders",
        type: MenuTypes.ITEM,
        icon: "inic inic-shopping-cart",
        href: PATHS.ORDERS,
        badge: "New",
        badgeBg: "secondary",
        badgeClassName: "badge-text",
      },
      {
        title: "Tickets",
        type: MenuTypes.COLLAPSE,
        icon: "inic inic-ticket",
        eventKey: PATHS.TICKETS,
        items: [
          {
            title: "Manage Tickets",
            type: MenuTypes.ITEM,
            href: PATHS.SUPPORT,
          },
          {
            title: "Ticket Reasons",
            type: MenuTypes.ITEM,
            href: `${PATHS.TICKETS}/${PATHS.TICKETS_REASONS}`,
          },
        ],
      },
      {
        title: "Phrase Moderation",
        type: MenuTypes.ITEM,
        badge: "New",
        badgeBg: "secondary",
        badgeClassName: "badge-text",
        href: PATHS.PHRASE_MODERATION,
        icon: "inic inic-format-bold",
      },
    ],
  },
  {
    title: "General",
    type: MenuTypes.GROUP,
    items: [
      {
        title: "Commission",
        type: MenuTypes.ITEM,
        badge: "New",
        badgeBg: "secondary",
        badgeClassName: "badge-text",
        href: PATHS.COMMISSION,
        icon: "inic inic-discount",
      },
    ],
  },
  {
    title: "",
    type: MenuTypes.GROUP,
    items: [
      {
        title: "CMS",
        type: MenuTypes.ITEM,
        badge: "New",
        badgeBg: "secondary",
        badgeClassName: "badge-text",
        href: `${PATHS.CONTENT}/${PATHS.STATIC_PAGES}`,
        icon: "inic inic-file",
      },
    ],
  },
];

export const MenuListCollapsed = [
  {
    title: "Dashboard",
    icon: "inic inic-home",
    href: PATHS.DASHBOARD,
  },
  {
    title: "Users",
    icon: "inic inic-person",
    href: PATHS.USER,
  },
  {
    title: "Orders",
    icon: "inic inic-shopping-cart",
    href: PATHS.ORDERS,
  },
  {
    title: "Tickets",
    icon: "inic inic-ticket",
    href: PATHS.TICKETS,
  },
  {
    title: "Phrase Moderation",
    icon: "inic inic-format-bold",
    href: PATHS.PHRASE_MODERATION,
  },
  {
    title: "Commission",
    icon: "inic inic-discount",
    href: PATHS.COMMISSION,
  },
  {
    title: "CMS",
    icon: "inic inic-file",
    href: `${PATHS.CONTENT}/${PATHS.STATIC_PAGES}`,
  },
];
