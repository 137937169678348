import { Buffer } from "buffer";

import moment from "moment-timezone";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

import "react-toastify/dist/ReactToastify.css";

import blogimg from "assets/images/blog-img.webp";
import favicon from "assets/images/indianic-short-logo.svg";
import logo from "assets/images/logo.svg";
// import noImage from "assets/images/no-image.png";
import profilepic from "assets/images/profile-pic.jpg";
import noImage from "assets/images/svg-images/no-image.svg";
// import apiConfig from "configs/apiConfig";
import generalConfig from "configs/generalConfig";
import { DEFAULT_DATE_FORMAT, DEFAULT_LANGUAGE_CODE } from "utils/constants";
import { localStorageKeys } from "utils/localStorageKeys";

/******************* 
@Purpose : Used for show message notification
@Parameter : text, type, autoClose, position
@Author : INIC
******************/
export const messageNotification = (
  text,
  type = "success",
  autoClose = generalConfig.TOAST_TIMER,
  position = "top-right"
) => {
  toast[type](text, {
    position: position,
    autoClose: autoClose,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

/******************* 
@purpose : Used for encode data into base 64
@Parameter : {data} 
@Author : INIC
******************/
export const encodeBase64 = (data) => {
  return Buffer.from(data).toString("base64");
};

/******************* 
@purpose : Used for decode data from base 64
@Parameter : {data} 
@Author : INIC
******************/
export const decodeBase64 = (data) => {
  return Buffer.from(data, "base64").toString("ascii");
};

/******************* 
@purpose : Used for content type
@Parameter : {} 
@Author : IndiaNIC
******************/
export const getHeader = (formData = false) => {
  return {
    "Content-Type": formData ? "multipart/form-data" : "application/json",
    Accept: "application/json",
  };
};

/******************* 
@purpose : Used for File upload extension
@Parameter : {} 
@Author : INIC
******************/
export const FILE_UPLOAD_EXTENSION = [
  "image/png", // for .png image
  "image/jpeg", // for .jpeg image
  "image/jpg", // for .jpg image
  // ".svg", // for .svg image
];

/******************* 
@purpose : Used for Remember Me Login
@Parameter : {} 
@Author : INIC
******************/

export const rememberMe = (clearTheme = true) => {
  const rememberMe = localStorage?.getItem(localStorageKeys.REMEMBER_ME);
  if (!rememberMe) {
    localStorage.clear();
  } else {
    if (clearTheme) {
      localStorage.removeItem(localStorageKeys.THEME);
    }
    localStorage.removeItem(localStorageKeys.EXPIRATIONTIME);
    localStorage.removeItem(localStorageKeys.IS_EMAIL);
    localStorage.removeItem(localStorageKeys.IS_MOBILE);
    localStorage.removeItem(localStorageKeys.ma_la);
    localStorage.removeItem(localStorageKeys.MASKED_EMAIL);
    localStorage.removeItem(localStorageKeys.MASKED_PHONE);
    localStorage.removeItem(localStorageKeys.OTP_TOKEN);
    localStorage.removeItem(localStorageKeys?.VERIFY_LOGIN_TOKEN);
  }
};

/******************* 
@purpose : Used for show default profile image onerror
@Parameter : {} 
@Author : INIC
******************/

export const setDefaultProfilePic = (e) => {
  e.currentTarget.src = profilepic;
  e.currentTarget.onerror = null;
};

/******************* 
@purpose : Used for show default no image onerror
@Parameter : {} 
@Author : INIC
******************/
export const setDefaultNoPic = (e) => {
  e.currentTarget.src = noImage;
  e.currentTarget.onerror = null;
};

/******************* 
@purpose : Used for show default profile image onerror
@Parameter : {} 
@Author : INIC
******************/

export const setDefaultLogo = (e) => {
  e.currentTarget.src = logo;
  e.currentTarget.onerror = null;
};
export const setDefaultFavicon = (e) => {
  e.currentTarget.src = favicon;
  e.currentTarget.onerror = null;
};

export const arrayToJson = (array, key) => {
  let data = {};
  for (let obj of array) {
    data[obj[key]] = obj;
  }
  return data;
};

export const jsonToArray = (obj) => Object.values(obj);

/******************* 
@purpose : Used for formatting date 
@Parameter : {date, dateFormat} 
@Author : INIC
******************/
export const getFormattedDate = (date, dateFormat) => {
  if (date === "") {
    return false;
  }
  if (dateFormat) {
    return moment(date).format(dateFormat);
  } else {
    return moment(date).format(DEFAULT_DATE_FORMAT);
  }
};

/******************* 
@purpose : Used for formatting date in date picker date format prop
@Parameter : {date, dateFormat} 
@Author : INIC
******************/
export const getFormattedDateWithTime = (dateFormat, timeFormat) => {
  if (timeFormat === "12 hours") {
    return `${dateFormat} h:mm aa`;
  } else if (timeFormat === "24 hours") {
    return `${dateFormat} HH:mm`;
  } else {
    return `${dateFormat} h:mm aa`;
  }
};

/******************* 
@purpose : Used for converting date according to timezone
@Parameter : {date, timezone} 
@Author : INIC
******************/
export const getTimeZoneConvertedDate = (date, timezone) => {
  if (date === "") {
    return false;
  }
  return moment.tz(date, timezone);
};

/******************* 
@purpose : Used for converting date to utc
@Parameter : {date} 
@Author : INIC
******************/
export const getUTCFormat = (date) => {
  return moment(date).utc().format("DD MMM YYYY h:mm a");
};

export const getFormattedTimeWithTimezone = (time, timezone, timeFormat) => {
  if (timeFormat === "12 hours") {
    return moment(time).tz(timezone).format("hh:mm A");
  } else if (timeFormat === "24 hours") {
    return moment(time).tz(timezone).format("HH:mm");
  } else {
    return moment(time).tz(timezone).format("hh:mm A");
  }
};

export const getFormattedDateTimeWithTimezone = (
  date,
  timezone,
  dateFormat,
  timeFormat
) => {
  if (timeFormat === "12 hours") {
    return moment(date)?.tz(timezone)?.format(`${dateFormat}-hh:mm A`);
  } else if (timeFormat === "24 hours") {
    return moment(date)?.tz(timezone)?.format(`${dateFormat}-HH:mm`);
  } else {
    return moment(date)?.tz(timezone)?.format(`${dateFormat}-hh:mm A`);
  }
};

/******************* 
@purpose : Used for show alert popup 
@Parameter : {} 
@Author : INIC
******************/
export const fireDeletePopup = async (
  confirmDeleteHandler,
  resetGridRowSelection,
  title
) => {
  const willDelete = await Swal.fire({
    title: title || "Are you sure, you want to delete?",
    icon: "warning",
    buttons: true,
    dangerMode: true,
    showCancelButton: true,
    confirmButtonText: "Delete",
    // cancelButtonText: "Cancel",
    // confirmButtonColor: "#A01B1B",
    // cancelButtonColor: "#999999",
    reverseButtons: true,
    focusConfirm: false,
    focusCancel: true,
  });

  if (willDelete.isConfirmed) {
    confirmDeleteHandler();
    resetGridRowSelection && resetGridRowSelection();
  }
};

export const getLanguageOptions = (data) => {
  return data?.map((list) => ({
    label: `${list?.language}(${list?.country})`,
    value: list?._id,
  }));
};

export const getLanguageCode = (data, id) => {
  return id && data
    ? data?.find((list) => list?._id === id)?.languageCode
    : DEFAULT_LANGUAGE_CODE;
};

/******************* 
@purpose : Used for setting default pic in blog card view 
@Parameter : {} 
@Author : INIC
******************/
export const setDefaultBlogImage = (e) => {
  e.currentTarget.src = blogimg;
  e.currentTarget.onerror = null;
};

export const noImageData = (e) => {
  e.currentTarget.src = blogimg;
  e.currentTarget.onerror = null;
};

/******************* 
@purpose : Used for select Option in react select 
@Parameter : {} 
@Author : INIC
******************/
export const selectOptionHandler = (formik, name) => {
  return formik?.values?.[name]
    ? {
        label: formik?.values?.[name],
        value: formik?.values?.[name],
      }
    : undefined;
};

/******************* 
@purpose : Used for conditional ternary 
@Parameter : {} 
@Author : INIC
******************/
export const conditionalTernaryHandler = (condition, param1, param2) => {
  return condition ? param1 : param2;
};

/******************* 
@purpose : Used for common ternary 
@Parameter : {} 
@Author : INIC
******************/
export const commonTernaryHandler = (param, param2) => {
  return param ? param : param2;
};

/******************* 
@purpose : Used for download file
@Parameter : {type,file} 
@Author : INIC
******************/
export const downlaodFiletoType = async (value) => {
  const csvData = value?.error?.data;
  if (!csvData) {
    alert("No Record Found");
    return;
  } else {
    // Split the CSV data into rows
    if (csvData.status !== false) {
      const rows = csvData?.split("\n");

      // Extract headers from the first row
      const headers = rows[0].split(",");

      // Extract data from remaining rows
      const parsedData = [];
      for (let i = 1; i < rows.length; i++) {
        const rowData = rows[i].split(",");
        if (rowData.length === headers.length) {
          const rowObject = {};
          for (let j = 0; j < headers.length; j++) {
            rowObject[headers[j]] = rowData[j];
          }
          parsedData.push(rowObject);
        }
      }

      // Generate a download link
      const csvContent =
        "data:text/csv;charset=utf-8," + encodeURIComponent(csvData);
      const link = document.createElement("a");
      link.setAttribute("href", csvContent);
      link.setAttribute("download", "data.csv");
      link.click();
    }
  }
};

// export const downlaodFiletoType = async (type, filepath) => {
//   let file = `${apiConfig.FILE_DOWNLOAD}/${type}/${filepath}`;
//   // window.open(file, "_self");
//   await fetch(file)
//     .then((res) => res.blob())
//     .then((data) => {
//       const url = window.URL.createObjectURL(new Blob([data]));
//       const link = document.createElement("a");
//       link.href = url;
//       link.setAttribute("download", filepath);
//       link.click();
//     });
// };

/******************* 
@purpose : Used for sorting array of objects
@Parameter : {arr, key} 
@Author : INIC
******************/
export const sortArrOfObj = (arr, key) => {
  return arr.sort((a, b) => {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  });
};

/******************* 
@purpose : Used for Capitalize data
@Parameter : {fullCapsWord} 
@Author : INIC
******************/
export const convertToCapitalized = (fullCapsWord) => {
  const capitalizedWord =
    fullCapsWord?.charAt(0)?.toUpperCase() +
    fullCapsWord?.slice(1)?.toLowerCase();
  return capitalizedWord;
};

export const getStreamChannels = async (params) => {
  try {
    const {
      searchText = "",
      id = null,
      offsetLength = 0,
      members = [],
      isArchived = false,
      type = [interfaceType.DIRECT_MESSAGE],
      order = -1,
      limit = 30,
    } = params;

    const filters = {
      type: {
        $in: type,
      },
      isArchived,
    };

    if (id) {
      filters.id = id;
    }
    if (members && Array.isArray(members) && members.length > 0) {
      filters.members = { $in: members };
    }
    if (searchText.length > 0) {
      filters.name = { $autocomplete: searchText.toString() };
    }
    const sort = {
      last_message_at: order,
    };
    const options = {
      limit,
      offset: offsetLength,
    };
    const channels = await streamClient.queryChannels(filters, sort, options);
    return channels;
  } catch (error) {
    //console.error(" getStreamChannels --->>>> ", error);
    return error;
  }
};
