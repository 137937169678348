import { messageNotification } from "./functions";

export const transformResponseHandler = (resp, alert = true) => {
  if (alert && resp?.message) {
    messageNotification(resp?.message, !resp?.status ? "error" : "success");
  }
  let transformedResp = {
    status: resp.status,
    data: resp.data,
    total: resp.total,
    extraMeta: resp.extraMeta,
  };
  if (resp.access_token) {
    transformedResp.access_token = resp.access_token;
    transformedResp.refreshToken = resp.refreshToken;
  }
  return transformedResp;
};

export const sentOtpTransformResponseHandler = (response, alert = true) => {
  if (alert && response?.data?.otp) {
    messageNotification(
      response?.data?.otp,
      !response?.status ? "error" : "success",
      5000
    );
  } else {
    messageNotification(
      response?.message,
      !response?.status ? "error" : "success"
    );
  }
  let otpTransformedResp = {
    status: response.status,
    data: response.data,
  };

  return otpTransformedResp;
};
