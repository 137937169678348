import { combineReducers } from "@reduxjs/toolkit";

import authApi from "store/api/auth";
import filterAPI from "store/api/common";
import blogApi from "store/api/content";
import dashboardApi from "store/api/dashboard";
import emailTemplatesApi from "store/api/emailTemplates";
import masterApi from "store/api/master";
import orderApi from "store/api/order";
import phraseModerationApi from "store/api/phraseModeration";
import profileApi from "store/api/profile";
import roleApi from "store/api/role";
import settingsApi from "store/api/settings";
import supportApi from "store/api/support";
import ticketsApi from "store/api/tickets";
import userApi from "store/api/users";
import userTokenReducer from "store/reducers/token";
import uiReducer from "store/reducers/ui";

import permissionsReducer from "./permissions";
/** ***************** 
@purpose : root reducer
@Author : INIC
@return : combine reducers
***************** */

export default () =>
  combineReducers({
    ui: uiReducer,
    token: userTokenReducer,
    permissions: permissionsReducer,
    [authApi.reducerPath]: authApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [masterApi.reducerPath]: masterApi.reducer,
    [settingsApi.reducerPath]: settingsApi.reducer,
    [roleApi.reducerPath]: roleApi.reducer,
    [emailTemplatesApi.reducerPath]: emailTemplatesApi.reducer,
    [blogApi.reducerPath]: blogApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [filterAPI.reducerPath]: filterAPI.reducer,
    [supportApi.reducerPath]: supportApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [phraseModerationApi.reducerPath]: phraseModerationApi.reducer,
    [ticketsApi.reducerPath]: ticketsApi.reducer,
  });

/** ***************** 
@purpose : RTK Query middlwares
@Author : INIC
@return : array of middlewares
***************** */
export const getReducerMiddlewares = () => [
  authApi.middleware,
  dashboardApi.middleware,
  profileApi.middleware,
  settingsApi.middleware,
  userApi.middleware,
  masterApi.middleware,
  roleApi.middleware,
  emailTemplatesApi.middleware,
  blogApi.middleware,
  filterAPI.middleware,
  supportApi.middleware,
  phraseModerationApi.middleware,
  orderApi.middleware,
  ticketsApi.middleware,
];
