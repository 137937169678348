import { RoutePermissionGate } from "components/layout/AccessControl";
import AuthLayout from "components/layout/AuthLayout";
import Layout from "components/layout/Layout";
import UiKitLayout from "components/layout/UiKitLayout";
import Loadable from "lib/suspenseLoader/Loadable";
import React, { lazy } from "react";
import { Navigate } from "react-router-dom";

import { PATHS } from "configs/routeConfig";

import GuestRoute from "./GuestRoutes";
import PrivateRoute from "./PrivateRoutes";

// import PrivateRoute from "./PrivateRoutes";

/** Auth */
const Login = Loadable(lazy(() => import("modules/auth/login/Login")));
const OtpDetails = Loadable(
  lazy(() => import("modules/auth/otpVerification/OtpDetails"))
);
const OtpVerification = Loadable(
  lazy(() => import("modules/auth/otpVerification/OtpVerification"))
);
const ForgotPassword = Loadable(
  lazy(() => import("modules/auth/forgotPassword/ForgotPassword"))
);
const ResetPassword = Loadable(
  lazy(() => import("modules/auth/resetPassword/ResetPassword"))
);

/** Dashboard */
const Dashboard = Loadable(lazy(() => import("modules/dashboard/Dashboard")));

/** User Management*/
const UserManagement = Loadable(
  lazy(() => import("modules/userManagement/UserManagement"))
);
const UserList = Loadable(
  lazy(() => import("modules/userManagement/userList/UserList"))
);
const AdminUsers = Loadable(
  lazy(() => import("modules/userManagement/adminUsers/AdminUsers"))
);
const UserDetails = Loadable(
  lazy(() => import("modules/userManagement/userDetails/ViewUserDetails"))
);
const AddUser = Loadable(
  lazy(() => import("modules/userManagement/userList/AddUser"))
);
const AdminUserDetails = Loadable(
  lazy(() => import("modules/userManagement/adminUsers/ViewAdminUserDetails"))
);
const AdminUserAdd = Loadable(
  lazy(() => import("modules/userManagement/adminUsers/AddEditAdminUser"))
);
const AdminUserEdit = Loadable(
  lazy(() => import("modules/userManagement/adminUsers/AddEditAdminUser"))
);

/** Orders*/
const Orders = Loadable(lazy(() => import("modules/orders/Orders")));

const ViewOrderDetails = Loadable(
  lazy(() => import("modules/orders/ViewOrderDetails"))
);

/** Phrase Moderation*/
const PhraseModeration = Loadable(
  lazy(() => import("modules/phraseModeration/PhraseModeration"))
);
const AddEditPhrase = Loadable(
  lazy(() => import("modules/phraseModeration/AddEditPhrase"))
);

/** Tickets Management*/
const TicketReasons = Loadable(
  lazy(() => import("modules/tickets/ticketReasons/TicketReasons"))
);

const AddEditReason = Loadable(
  lazy(() => import("modules/tickets/ticketReasons/AddEditReason"))
);

/** Commission*/

const Commission = Loadable(
  lazy(() => import("modules/commission/Commission"))
);

/** Content Management*/
const ContentManagement = Loadable(
  lazy(() => import("modules/contentManagement/ContentManagement"))
);
const StaticPages = Loadable(
  lazy(() => import("modules/contentManagement/staticPages/StaticPages"))
);
const StatisPagesViewDetails = Loadable(
  lazy(() =>
    import("modules/contentManagement/staticPages/ViewStaticPageDetails")
  )
);
const AddStaticPage = Loadable(
  lazy(() =>
    import(
      "modules/contentManagement/staticPages/StaticPagesActions/AddStaticPage"
    )
  )
);

/** Master Management */
const MasterManagement = Loadable(
  lazy(() => import("modules/masterManagement/MasterManagement"))
);
const CountryPage = Loadable(
  lazy(() => import("modules/masterManagement/country/Country"))
);
const AddCountry = Loadable(
  lazy(() => import("modules/masterManagement/country/AddCountry"))
);
const AddLanguage = Loadable(
  lazy(() => import("modules/masterManagement/language/AddLanguage"))
);
const TimezonePage = Loadable(
  lazy(() => import("modules/masterManagement/timezone/Timezone"))
);
const LanguagePage = Loadable(
  lazy(() => import("modules/masterManagement/language/Language"))
);

/** Support */
const SupportManagement = Loadable(
  lazy(() => import("modules/support/SupportManagement"))
);
const Support = Loadable(lazy(() => import("modules/support/Support")));
const TicketDetail = Loadable(
  lazy(() => import("modules/support/TicketDetails"))
);
/** Settings */
const Settings = Loadable(lazy(() => import("modules/settings/Settings")));
const GeneralSettings = Loadable(
  lazy(() => import("modules/settings/generalSettings/GeneralSettings"))
);
const EmailNotifications = Loadable(
  lazy(() => import("modules/settings/emailNotifications/EmailNotifications"))
);
const PaymentGateway = Loadable(
  lazy(() => import("modules/settings/paymentGateway/PaymentGateway"))
);
const SmtpSmsDetails = Loadable(
  lazy(() => import("modules/settings/smtpSmsDetails/SmtpSmsDetails"))
);
const SocialMedia = Loadable(
  lazy(() => import("modules/settings/socialMedial/SocialMedia"))
);
const LanguageMessages = Loadable(
  lazy(() => import("modules/settings/langaugePreferences/LanguageMessages"))
);
const AddLanguageMessages = Loadable(
  lazy(() => import("modules/settings/langaugePreferences/AddLanguageMessage"))
);
const LangaugeStaticLang = Loadable(
  lazy(() => import("modules/settings/langaugePreferences/LanguageStaticLang"))
);
const AddLanguageStaticLang = Loadable(
  lazy(() => import("modules/settings/langaugePreferences/AddStaticLabel"))
);
/** Master Management */
// const CommonPages = Loadable(lazy(() => import("modules/commonPages/CommonPages")));
const FourZeroFourPage = Loadable(
  lazy(() => import("modules/commonPages/FourZeroFourPage"))
);
const ThankyouPage = Loadable(
  lazy(() => import("modules/commonPages/ThankyouPage"))
);
const ComingSoonPage = Loadable(
  lazy(() => import("modules/commonPages/ComingSoonPage"))
);

/** My Profile */
const MyProfile = Loadable(lazy(() => import("modules/myProfile/MyProfile")));
const EditMyProfile = Loadable(
  lazy(() => import("modules/myProfile/EditProfile"))
);

/** Change Password */
const ChangePasswordPage = Loadable(
  lazy(() => import("modules/changePassword/ChangePassword"))
);

/** UI Kit */
const UiKit = Loadable(lazy(() => import("modules/uiKit/UiKit")));
const UiTypography = Loadable(
  lazy(() => import("modules/uiKit/uiComponents/UiTypograpgy"))
);
const UiColors = Loadable(
  lazy(() => import("modules/uiKit/uiComponents/UiColors"))
);
const UiForms = Loadable(
  lazy(() => import("modules/uiKit/uiComponents/UiForms"))
);
const UiCards = Loadable(
  lazy(() => import("modules/uiKit/uiComponents/UiCards"))
);
const UiBlocks = Loadable(
  lazy(() => import("modules/uiKit/uiComponents/UiBlocks"))
);

const MainRoutes = {
  path: PATHS.DEFAULT,
  element: (
    <PrivateRoute>
      <Layout />
    </PrivateRoute>
  ),
  children: [
    // Dashboard
    {
      path: PATHS.DASHBOARD,
      element: <Dashboard />,
    },
    // User management
    {
      path: PATHS.USER,
      element: <UserManagement />,
      children: [
        {
          // index: true,
          path: PATHS.USER_LIST,
          element: (
            <RoutePermissionGate>
              <UserList />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.USER_LIST + "/" + PATHS.VIEW,
          element: (
            <RoutePermissionGate>
              <UserDetails />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.USER_LIST + "/" + PATHS.CREATE,
          element: (
            <RoutePermissionGate>
              <AddUser type="add" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.USER_LIST + "/" + PATHS.EDIT,
          element: (
            <RoutePermissionGate>
              <AddUser type="edit" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.ADMIN_USER,
          element: (
            <RoutePermissionGate>
              <AdminUsers />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.ADMIN_USER + "/" + PATHS.VIEW + "/:id",
          element: (
            <RoutePermissionGate>
              <AdminUserDetails type="view" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.ADMIN_USER + "/" + PATHS.CREATE,
          element: (
            <RoutePermissionGate>
              <AdminUserAdd type="add" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.ADMIN_USER + "/" + PATHS.EDIT + "/:id",
          element: (
            <RoutePermissionGate>
              <AdminUserEdit type="edit" />
            </RoutePermissionGate>
          ),
        },
        {
          path: "",
          element: <Navigate to={`${PATHS.USER}/${PATHS.USER_LIST}`} />,
        },
        {
          path: PATHS.ANY,
          element: <Navigate to={`${PATHS.USER}/${PATHS.USER_LIST}`} />,
        },
      ],
    },

    //Orders
    {
      path: PATHS.ORDERS,
      element: <Orders />,
    },
    {
      path: PATHS.ORDERS + "/" + PATHS.VIEW,
      element: (
        <RoutePermissionGate>
          <ViewOrderDetails />
        </RoutePermissionGate>
      ),
    },

    //Phrase Moderation
    {
      path: PATHS.PHRASE_MODERATION,
      element: <PhraseModeration />,
    },
    {
      path: PATHS.PHRASE_MODERATION + "/" + PATHS.CREATE,
      element: <AddEditPhrase type="add" />,
    },
    {
      path: PATHS.PHRASE_MODERATION + "/" + PATHS.EDIT + "/" + ":id",
      element: <AddEditPhrase type="edit" />,
    },

    //Tickets Management

    {
      path: PATHS.TICKETS + "/" + PATHS.TICKETS_REASONS,
      element: <TicketReasons />,
    },
    {
      path: PATHS.TICKETS + "/" + PATHS.TICKETS_REASONS + "/" + PATHS.CREATE,
      element: <AddEditReason type="add" />,
    },
    {
      path:
        PATHS.TICKETS +
        "/" +
        PATHS.TICKETS_REASONS +
        "/" +
        PATHS.EDIT +
        "/" +
        ":id",
      element: <AddEditReason type="edit" />,
    },

    //Commission
    {
      path: PATHS.COMMISSION,
      element: <Commission />,
    },

    // Content management
    {
      path: PATHS.CONTENT,
      element: <ContentManagement />,
      children: [
        // Static Pages
        {
          path: PATHS.STATIC_PAGES,
          element: (
            <RoutePermissionGate>
              <StaticPages />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.STATIC_PAGES + "/" + PATHS.VIEW,
          element: (
            <RoutePermissionGate>
              <StatisPagesViewDetails />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.STATIC_PAGES + "/" + PATHS.CREATE,
          element: (
            <RoutePermissionGate>
              <AddStaticPage type="add" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.STATIC_PAGES + "/" + PATHS.EDIT,
          element: (
            <RoutePermissionGate>
              <AddStaticPage type="edit" />
            </RoutePermissionGate>
          ),
        },

        {
          path: "",
          element: <Navigate to={`${PATHS.CONTENT}/${PATHS.STATIC_PAGES}`} />,
        },
        {
          path: PATHS.ANY,
          element: <Navigate to={`${PATHS.CONTENT}/${PATHS.STATIC_PAGES}`} />,
        },
      ],
    },

    // Master Management
    {
      path: PATHS.MASTER_MANAGEMENT,
      element: <MasterManagement />,
      children: [
        {
          path: PATHS.COUNTRY,
          element: (
            <RoutePermissionGate>
              <CountryPage />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.COUNTRY + "/" + PATHS.CREATE,
          element: (
            <RoutePermissionGate>
              <AddCountry type="add" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.TIMEZONE,
          element: (
            <RoutePermissionGate>
              <TimezonePage />
            </RoutePermissionGate>
          ),
        },

        {
          path: PATHS.LANGUAGE,
          element: (
            <RoutePermissionGate>
              <LanguagePage />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.LANGUAGE + "/" + PATHS.CREATE,
          element: (
            <RoutePermissionGate>
              <AddLanguage type="add" />
            </RoutePermissionGate>
          ),
        },
        {
          path: "",
          element: (
            <Navigate to={`${PATHS.MASTER_MANAGEMENT}/${PATHS.COUNTRY}`} />
          ),
        },
        {
          path: PATHS.ANY,
          element: (
            <Navigate to={`${PATHS.MASTER_MANAGEMENT}/${PATHS.COUNTRY}`} />
          ),
        },
      ],
    },
    // Support

    {
      path: PATHS.SUPPORT,
      element: <SupportManagement />,
      children: [
        {
          path: "",
          element: (
            <RoutePermissionGate>
              <Support />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.VIEW + "/:id",
          element: (
            <RoutePermissionGate>
              <TicketDetail />
            </RoutePermissionGate>
          ),
        },
      ],
    },

    // Settings
    {
      path: PATHS.SETTINGS,
      element: <Settings />,
      children: [
        {
          path: PATHS.GENERAL_SETTINGS,
          element: (
            <RoutePermissionGate>
              <GeneralSettings />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.EMAIL_NOTIFICATIONS,
          element: (
            <RoutePermissionGate>
              <EmailNotifications />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.PAYMENT_GATEWAY,
          element: (
            <RoutePermissionGate>
              <PaymentGateway />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.SMTP_SMS_DETAILS,
          element: (
            <RoutePermissionGate>
              <SmtpSmsDetails />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.SOCIAL_MEDIA,
          element: (
            <RoutePermissionGate>
              <SocialMedia />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.LANGUAGE_MESSAGES,
          element: (
            <RoutePermissionGate>
              <LanguageMessages />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.LANGUAGE_MESSAGES + "/" + PATHS.CREATE,
          element: (
            <RoutePermissionGate>
              <AddLanguageMessages type="add" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.LANGAUGE_STATIC_LANG,
          element: (
            <RoutePermissionGate>
              <LangaugeStaticLang />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.LANGAUGE_STATIC_LANG + "/" + PATHS.CREATE,
          element: (
            <RoutePermissionGate>
              <AddLanguageStaticLang type="add" />
            </RoutePermissionGate>
          ),
        },
        {
          path: "",
          element: (
            <Navigate to={`${PATHS.SETTINGS}/${PATHS.GENERAL_SETTINGS}`} />
          ),
        },
        {
          path: PATHS.ANY,
          element: (
            <Navigate to={`${PATHS.SETTINGS}/${PATHS.GENERAL_SETTINGS}`} />
          ),
        },
      ],
    },
    // Common Pages
    {
      path: PATHS.FourZeroFour,
      element: <FourZeroFourPage />,
    },
    {
      path: PATHS.THANKYOU,
      element: <ThankyouPage />,
    },
    {
      path: PATHS.COMING_SOON,
      element: <ComingSoonPage />,
    },
    // My Profile
    {
      path: PATHS.MY_PROFILE,
      element: <MyProfile />,
    },
    {
      path: PATHS.EDIT_MY_PROFILE,
      element: <EditMyProfile />,
    },
    {
      path: PATHS.CHANGE_PASSWORD,
      element: <ChangePasswordPage />,
    },
    // UI Kit
    {
      path: PATHS.UI_KIT,
      element: (
        <UiKitLayout>
          <UiKit />
        </UiKitLayout>
      ),
      exact: true,
      children: [
        {
          path: PATHS.TYPOGRAPHY,
          element: <UiTypography />,
        },
        {
          path: PATHS.COLORS,
          element: <UiColors />,
        },
        {
          path: PATHS.FORMS,
          element: <UiForms />,
        },
        {
          path: PATHS.CARDS,
          element: <UiCards />,
        },
        {
          path: PATHS.BLOCKS,
          element: <UiBlocks />,
        },
        {
          path: PATHS.ANY,
          element: <Navigate to={PATHS.UI_KIT} />,
        },
      ],
    },
  ],
};

const AuthenticationRoutes = {
  path: PATHS.DEFAULT,
  element: (
    <GuestRoute>
      <AuthLayout />
    </GuestRoute>
  ),
  children: [
    {
      path: PATHS.DEFAULT,
      element: <Login />,
    },
    {
      path: PATHS.OTP_DETAILS,
      element: <OtpDetails />,
    },
    {
      path: PATHS.OTP_VERIFICATION,
      element: <OtpVerification />,
    },
    {
      path: PATHS.FORGOT_PASSWORD,
      element: <ForgotPassword />,
    },
    {
      path: PATHS.RESET_PASSWORD,
      element: <ResetPassword />,
    },
  ],
};

/**
 * @params {boolean}
 * @return {Array} of all paths & routes
 */
export const getRoutes = () => [
  AuthenticationRoutes,
  MainRoutes,
  {
    path: PATHS.ANY,
    element: <FourZeroFourPage />,
  },
];
