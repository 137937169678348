import { useEffect, useState } from "react";

import { localStorageKeys } from "utils/localStorageKeys";

const THEMES = {
  LIGHT: "light",
  DARK: "dark",
};

export const useThemeHook = (isThemeDark) => {
  const [theme, setTheme] = useState(
    localStorage.getItem(localStorageKeys.THEME) || THEMES.LIGHT
  );

  useEffect(() => {
    if (isThemeDark) {
      let updateTheme = isThemeDark ? THEMES.DARK : theme;
      localStorage.setItem(localStorageKeys.THEME, updateTheme);
      updateHtmlTag(updateTheme);
      setTheme(updateTheme);
    }
  }, [isThemeDark]);

  const updateHtmlTag = (theme) => {
    const htmlEle = document.getElementsByTagName("html")[0];
    htmlEle.setAttribute("data-theme", theme);
  };

  const setInitialTheme = () => {
    let currentTheme =
      localStorage.getItem(localStorageKeys.THEME) || THEMES.LIGHT;
    updateHtmlTag(currentTheme);
    setTheme(currentTheme);
  };

  const toggleTheme = () => {
    let updatedTheme = theme === "dark" ? THEMES.LIGHT : THEMES.DARK;
    updateHtmlTag(updatedTheme);
    setTheme(updatedTheme);
    localStorage.setItem(localStorageKeys.THEME, updatedTheme);
  };

  return { theme, toggleTheme, setInitialTheme, updateHtmlTag, THEMES };
};
