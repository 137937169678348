import { useGetTableColumnSettingsQuery } from "store/api/auth";
import {
  useChangePasswordMutation,
  useDeleteProfileMutation,
  useEditCommissionMutation,
  useEditProfileMutation,
  useFileUploadMutation,
  useGlobalConfigQuery,
  useLazyGetCommissionDataQuery,
  useProfileQuery,
} from "store/api/profile";
import { fixedCacheKeys } from "utils/fixedCacheKeys";

export const useProfileHook = () => {
  /** ***************** 
  @purpose : Used For handle get and reset data
  @Parameter : {data,reset}
  @Author : INIC
  ***************** */

  const { data: userData, refetch: refetchUserData } = useProfileQuery();
  // forcing the query to always fetch when this component is mounted
  // { refetchOnMountOrArgChange: true }
  const [editProfile, { isLoading: profileLoading }] = useEditProfileMutation({
    fixedCacheKey: fixedCacheKeys.EDIT_PROFILE,
  });

  const [fileUpload] = useFileUploadMutation({
    fixedCacheKey: fixedCacheKeys.FILE_UPLOAD,
  });

  const { data: globalConfigData } = useGlobalConfigQuery({
    refetchOnMountOrArgChange: true,
  });

  const [changePassword, { isLoading: passwordLoading }] =
    useChangePasswordMutation({
      fixedCacheKey: fixedCacheKeys.CHANGE_PASSWORD,
    });

  const [deleteProfile, { isLoading: deleteProfileLoading }] =
    useDeleteProfileMutation({
      fixedCacheKey: fixedCacheKeys.DELETE_PROFILE,
    });

  const [getCommissionData, { data: commissionData }] =
    useLazyGetCommissionDataQuery({
      fixedCacheKey: fixedCacheKeys.GET_COMMISSION,
    });

  const [editCommission, { isLoading: commissionLoading }] =
    useEditCommissionMutation({
      fixedCacheKey: fixedCacheKeys.EDIT_COMMISSION,
    });

  const { data: columnTableSettingData } = useGetTableColumnSettingsQuery();

  return {
    changePassword,
    passwordLoading,
    profileLoading,
    userData,
    editProfile,
    fileUpload,
    refetchUserData,
    globalConfigData,
    deleteProfile,
    deleteProfileLoading,
    columnTableSettingData,
    editCommission,
    commissionLoading,
    getCommissionData,
    commissionData,
  };
};
