import * as types from "store/actions";

/** ***************** 
@purpose :  Handle Modal View
@Parameter : {value, key}
@Author : INIC
******************/
export const toggleModal = (value, key) => ({
  type: types.toggleUiModal,
  value,
  key,
});

/** ***************** 
@purpose :  Handle Loader 
@Parameter : {value, key}
@Author : INIC
******************/
export const toggleLoader = (value, key) => ({
  type: types.toggleLoader,
  value,
  key,
});

/** ***************** 
@purpose :  set Language 
@Parameter : {value}
@Author : INIC
******************/
export const setUILanguage = (value, key) => {
  return {
    type: types.setUILanguage,
    value,
    key,
  };
};

/** ***************** 
@purpose :  set Language object on application start with default language id
@Parameter : {value}
@Author : INIC
******************/
export const setUILanguageWithDefault = (value) => {
  return {
    type: types.setUILanguageWithDefault,
    value,
  };
};
