import Footer from "components/footer/Footer";
import Header from "components/header/Header";
import React from "react";
import Container from "react-bootstrap/Container";
import { Outlet } from "react-router-dom";

import { useModalBlurEffect } from "hooks/common/useModalBlurEffect";

function Layout() {
  const { showBlur } = useModalBlurEffect();
  return (
    <>
      <Header showBlur={showBlur} />
      <div className={`global-body${showBlur ? " show-blur" : ""}`}>
        <Container fluid>
          <Outlet />
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default Layout;
