import { createApi } from "@reduxjs/toolkit/query/react";

import apiConfig from "configs/apiConfig";
import { REDUCER_PATHS } from "store/config";
import { transformResponseHandler } from "utils/transformResponseHandler";

import { customFetchBase } from ".";

const filterAPI = createApi({
  reducerPath: REDUCER_PATHS.FILTER,
  baseQuery: customFetchBase,

  endpoints: (builder) => ({
    saveFilter: builder.mutation({
      query: (body) => ({
        url: apiConfig.SAVE_FILTER,
        method: "POST",
        body: body,
      }),
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    getFilter: builder.mutation({
      query: (key) => ({
        url: apiConfig.SAVE_FILTER + "/" + key,
        method: "GET",
      }),
    }),
    deleteFilter: builder.mutation({
      query: (id) => ({
        url: apiConfig.SAVE_FILTER + "/" + id,
        method: "DELETE",
      }),
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    saveColumn: builder.mutation({
      query: (body) => ({
        url: apiConfig.SAVE_COLUMN,
        method: "POST",
        body: body,
      }),
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    getColumn: builder.mutation({
      query: (key) => ({
        url: apiConfig.SAVE_COLUMN + "/" + key,
        method: "GET",
      }),
    }),
    deleteColumn: builder.mutation({
      query: (id) => ({
        url: apiConfig.SAVE_COLUMN + "/" + id,
        method: "DELETE",
      }),
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
  }),
});

export const {
  useDeleteFilterMutation,
  useSaveFilterMutation,
  useGetFilterMutation,
  useSaveColumnMutation,
  useGetColumnMutation,
  useDeleteColumnMutation,
} = filterAPI;

export default filterAPI;
