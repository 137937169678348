import React from "react";

import { useThemeHook } from "hooks/profile/useThemeHook";

export function ThemeToggle({ isThemeDark, handleThemeUpdate }) {
  const { theme, toggleTheme, THEMES } = useThemeHook(isThemeDark);
  return (
    <div className="theme-toggle-switch">
      <input
        type="checkbox"
        className="switch-theme"
        id="theme-toggle"
        checked={theme === THEMES.DARK}
        onChange={() => {
          handleThemeUpdate(theme !== THEMES.DARK);
          toggleTheme();
        }}
      />
      <label htmlFor="theme-toggle" className="switch-theme-label">
        <span className="inic inic-sun"></span>
        <span className="inic inic-moon"></span>
      </label>
    </div>
  );
}
