/** ***************** 
  @purpose : Used For Regex
  @Parameter : {}
  @Author : INIC
  ***************** */

export const InputValidator = {
  phoneRegExp: /([+]?\d{1,2}[.-\s]?)?(\d{3}[.-]?){2}\d{4}/,
  passwordRegExp:
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
  emailRegExp:
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  otpRegExp: /[0-9]{6,6}$/,
  alphaOnlyRegx: /^[^\d]+$/,
  alphaNumOnlyRegx: /^[a-zA-Z0-9 ]*$/,
};

/** ***************** 
  @purpose : Used For Default language for whole UNiversal Admin
  @Parameter : {}
  @Author : INIC
  ***************** */
export const DEFAULT_LANGUAGE_CODE = "en";
export const DEFAULT_LANGUAGE_NAME = "English";
export const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";
