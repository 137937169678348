import React from "react";
import {
  Navigate,
  //useLocation
} from "react-router-dom";

import { PATHS } from "configs/routeConfig";
import { useAuthHook } from "hooks/auth/useAuthHook";

/**
 * Guest Route
 */
export default function GuestRoute({ children }) {
  const { access_token } = useAuthHook();
  // const location = useLocation();

  if (access_token) {
    return (
      <Navigate to={PATHS.DASHBOARD} />

      // <Navigate
      //   to={location?.state?.from ?? PATHS.DASHBOARD}
      //   replace
      //   state={{
      //     from: location?.state?.from ?? PATHS.DASHBOARD,
      //   }}
      // />
    );
  }

  return children;
}
