import { createApi } from "@reduxjs/toolkit/query/react";

import apiConfig from "configs/apiConfig";
import { REDUCER_PATHS } from "store/config";
import { transformResponseHandler } from "utils/transformResponseHandler";

import { customFetchBase } from ".";

const settingsApi = createApi({
  reducerPath: REDUCER_PATHS.SETTINGS,
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    addMetaData: builder.mutation({
      query: (data) => ({
        url: apiConfig.META_DATA,
        body: data.postData,
        method: "POST",
      }),
      invalidatesTags: ["MetaData"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editMetaData: builder.mutation({
      query: (data) => ({
        url: apiConfig.META_DATA + "/" + data?.id,
        body: data.postData,
        method: "PUT",
      }),
      invalidatesTags: ["MetaData"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),

    metaData: builder.query({
      query: (id) => ({
        url: `${apiConfig.META_DATA}?languageId=${id}`,
        method: "GET",
      }),
      providesTags: ["MetaData"],
    }),

    addSiteSettingData: builder.mutation({
      query: (data) => ({
        url: apiConfig.SITE_SETTING,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["SiteSettingData"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editSiteSettingData: builder.mutation({
      query: (data) => ({
        url: apiConfig.SITE_SETTING + "/" + data?.id,
        body: data.postData,
        method: "PUT",
      }),
      invalidatesTags: ["SiteSettingData"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    siteSettingData: builder.query({
      query: (id) => ({
        url: `${apiConfig.SITE_SETTING}?languageId=${id}`,
        method: "GET",
      }),
      providesTags: ["SiteSettingData"],
    }),
    addAnalyticalData: builder.mutation({
      query: (data) => ({
        url: apiConfig.ANALYTICAL_DATA,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["AnalyticalData"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editAnalyticalData: builder.mutation({
      query: (data) => ({
        url: apiConfig.ANALYTICAL_DATA + "/" + data?.id,
        body: data.postData,
        method: "PUT",
      }),
      invalidatesTags: ["AnalyticalData"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    analyticalData: builder.query({
      query: () => ({
        url: apiConfig.ANALYTICAL_DATA,
        method: "GET",
      }),
      providesTags: ["AnalyticalData"],
    }),
    addSiteUnMaintainanceData: builder.mutation({
      query: (data) => ({
        url: apiConfig.SITE_UN_MAINTENANCE,
        body: data.postData,
        method: "POST",
      }),
      invalidatesTags: ["SiteUnMaintainanceData"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editSiteUnMaintainanceData: builder.mutation({
      query: (data) => ({
        url: apiConfig.SITE_UN_MAINTENANCE + "/" + data?.id,
        body: data.postData,
        method: "PUT",
      }),
      invalidatesTags: ["SiteUnMaintainanceData"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    siteUnMaintainanceData: builder.query({
      query: (id) => ({
        url: `${apiConfig.SITE_UN_MAINTENANCE}?languageId=${id}`,
        method: "GET",
      }),
      providesTags: ["SiteUnMaintainanceData"],
    }),
    dateTimeData: builder.query({
      query: () => ({
        url: apiConfig.DATE_TIME,
        method: "GET",
      }),
      providesTags: ["DateTimeData"],
    }),
    addDateTimeData: builder.mutation({
      query: (data) => ({
        url: apiConfig.DATE_TIME,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["DateTimeData"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editDateTimeData: builder.mutation({
      query: (data) => ({
        url: apiConfig.DATE_TIME + "/" + data?.id,
        body: data.postData,
        method: "PUT",
      }),
      invalidatesTags: ["DateTimeData"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    socialMediaSdk: builder.query({
      query: () => ({
        url: apiConfig.SOCIAL_MEDIA_SDK,
        method: "GET",
      }),
      providesTags: ["SocialMediaSdk"],
    }),
    addSocialMediaSdk: builder.mutation({
      query: (data) => ({
        url: apiConfig.SOCIAL_MEDIA_SDK,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["SocialMediaSdk"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editSocialMediaSdk: builder.mutation({
      query: (data) => ({
        url: apiConfig.SOCIAL_MEDIA_SDK + "/" + data?.id,
        body: data?.postData,
        method: "PUT",
      }),
      invalidatesTags: ["SocialMediaSdk"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    socialMediaLink: builder.query({
      query: () => ({
        url: apiConfig.SOCIAL_MEDIA_LINK,
        method: "GET",
      }),
      providesTags: ["SocialMediaLink"],
    }),
    addSocialMediaLink: builder.mutation({
      query: (data) => ({
        url: apiConfig.SOCIAL_MEDIA_LINK,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["SocialMediaLink"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editSocialMediaLink: builder.mutation({
      query: (data) => ({
        url: apiConfig.SOCIAL_MEDIA_LINK + "/" + data?.id,
        body: data?.postData,
        method: "PUT",
      }),
      invalidatesTags: ["SocialMediaLink"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    changeStatus: builder.mutation({
      query: (data) => ({
        url: apiConfig.CHANGE_STATUS,
        body: data,
        method: "PATCH",
      }),
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    smtpDetails: builder.query({
      query: () => ({
        url: apiConfig.SMTP_DETAILS,
        method: "GET",
      }),
      providesTags: ["SmtpDetails"],
    }),
    addSmtpDetails: builder.mutation({
      query: (data) => ({
        url: apiConfig.SMTP_DETAILS,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["SmtpDetails"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editSmtpDetails: builder.mutation({
      query: (data) => ({
        url: apiConfig.SMTP_DETAILS + "/" + data?.id,
        body: data?.postData,
        method: "PUT",
      }),
      invalidatesTags: ["SmtpDetails"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    smsDetails: builder.query({
      query: () => ({
        url: apiConfig.SMS_DETAILS,
        method: "GET",
      }),
      providesTags: ["SmsDetails"],
    }),
    addSmsDetails: builder.mutation({
      query: (data) => ({
        url: apiConfig.SMS_DETAILS,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["SmsDetails"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editSmsDetails: builder.mutation({
      query: (data) => ({
        url: apiConfig.SMS_DETAILS + "/" + data?.id,
        body: data?.postData,
        method: "PUT",
      }),
      invalidatesTags: ["SmsDetails"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    notification: builder.query({
      query: () => ({
        url: apiConfig.NOTIFICATION,
        method: "GET",
      }),
      providesTags: ["Notification"],
    }),
    notificationStatus: builder.mutation({
      query: (data) => ({
        url: apiConfig.NOTIFICATION,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Notification"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    languageMessages: builder.query({
      query: (data) => ({
        url: `${apiConfig.LANGUAGE_MESSAGES}?page=${data.page}&pageSize=${data.pageSize}&languageId=${data?.languageId}`,
        method: "GET",
      }),
    }),
    addEditLanguageMessages: builder.mutation({
      query: (data) => ({
        url: data.id
          ? `${apiConfig.LANGUAGE_MESSAGES}/${data.id}`
          : apiConfig.LANGUAGE_MESSAGES,
        body: data.postData,
        method: data?.id ? "PUT" : "POST",
      }),
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    languageLabel: builder.query({
      query: (data) => ({
        url: `${apiConfig.LANGUAGE_LABEL}?page=${data.page}&pageSize=${data.pageSize}&languageId=${data?.languageId}`,
        method: "GET",
      }),
    }),
    addEditLanguageLabel: builder.mutation({
      query: (data) => ({
        url: data.id
          ? `${apiConfig.LANGUAGE_LABEL}/${data.id}`
          : apiConfig.LANGUAGE_LABEL,
        body: data.postData,
        method: data?.id ? "PUT" : "POST",
      }),
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    addPayment: builder.mutation({
      query: (data) => ({
        url: apiConfig.ADD_EDIT_PAYMENT,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["Payment"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editPayment: builder.mutation({
      query: (data) => ({
        url: apiConfig.ADD_EDIT_PAYMENT + "/" + data?.id,
        body: data?.postData,
        method: "PUT",
      }),
      invalidatesTags: ["Payment"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    paymentDetails: builder.query({
      query: () => ({
        url: apiConfig.ADD_EDIT_PAYMENT,
        method: "GET",
      }),
      providesTags: ["Payment"],
    }),
    twoWayAuthentication: builder.mutation({
      query: (data) => ({
        url: apiConfig.TWO_WAY_AUTHENTICATION,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["twoFactorAuthentication"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    getTwoWayAuthentication: builder.query({
      query: () => ({
        url: apiConfig.GET_TWO_WAY_AUTHENTICATION,
        method: "GET",
      }),
      providesTags: ["twoFactorAuthentication"],
    }),
  }),
});

export const {
  useAddMetaDataMutation,
  useEditMetaDataMutation,
  useMetaDataQuery,
  useAddSiteSettingDataMutation,
  useSiteSettingDataQuery,
  useEditSiteSettingDataMutation,
  useAddAnalyticalDataMutation,
  useEditAnalyticalDataMutation,
  useAnalyticalDataQuery,
  useAddSiteUnMaintainanceDataMutation,
  useSiteUnMaintainanceDataQuery,
  useDateTimeDataQuery,
  useAddDateTimeDataMutation,
  useSocialMediaSdkQuery,
  useAddSocialMediaSdkMutation,
  useSocialMediaLinkQuery,
  useAddSocialMediaLinkMutation,
  useChangeStatusMutation,
  useSmtpDetailsQuery,
  useAddSmtpDetailsMutation,
  useSmsDetailsQuery,
  useAddSmsDetailsMutation,
  useNotificationQuery,
  useNotificationStatusMutation,
  useLazyLanguageMessagesQuery,
  useLazyLanguageLabelQuery,
  useAddEditLanguageMessagesMutation,
  useAddEditLanguageLabelMutation,
  useAddPaymentMutation,
  usePaymentDetailsQuery,
  useTwoWayAuthenticationMutation,
  useEditSiteUnMaintainanceDataMutation,
  useEditDateTimeDataMutation,
  useEditSocialMediaLinkMutation,
  useEditSocialMediaSdkMutation,
  useEditPaymentMutation,
  useEditSmtpDetailsMutation,
  useEditSmsDetailsMutation,
  useGetTwoWayAuthenticationQuery,
} = settingsApi;

export default settingsApi;
