import { useSelector } from "react-redux";

import {
  useForgotPasswordMutation,
  useLoginMutation,
  useLogoutMutation,
  useResetPassMutation,
  useSendOtpMutation,
  useVerifyOtpMutation,
} from "store/api/auth";
import { fixedCacheKeys } from "utils/fixedCacheKeys";

export const useAuthHook = () => {
  /** ***************** 
  @purpose : Used For handle get and reset data
  @Parameter : {data,reset}
  @Author : INIC
  ***************** */

  const [login, { data, isLoading, reset: resetLoginDetails }] =
    useLoginMutation({
      fixedCacheKey: fixedCacheKeys.LOGIN,
    });

  const [
    sendOtp,
    { data: otpDetails, reset: resetOtpDetails, isLoading: sendOtpLoading },
  ] = useSendOtpMutation({
    fixedCacheKey: fixedCacheKeys.SEND_OTP,
  });

  const [
    verifyOtp,
    {
      data: verificationData,
      reset: resetOtpVerification,
      isLoading: verifyLoading,
    },
  ] = useVerifyOtpMutation({
    fixedCacheKey: fixedCacheKeys.VERIFY_OTP,
  });

  const [logoutUser, { reset: resetLogoutData }] = useLogoutMutation({
    fixedCacheKey: fixedCacheKeys.LOGOUT,
  });

  const [forgotPassword, { isLoading: forgotPassLoading }] =
    useForgotPasswordMutation({
      fixedCacheKey: fixedCacheKeys.FORGOT_PASSWORD,
    });

  const [resetPass, { isLoading: resetPassLoading, reset: resetPassword }] =
    useResetPassMutation({
      fixedCacheKey: fixedCacheKeys.RESET_PASSWORD,
    });

  const access_token = useSelector((state) => state.token.access_token);
  const get_stream_token = useSelector((state) => state.token.getStreamToken);
  const userData = useSelector((state) => state.token.data);

  const resetAuthData = () => {
    resetLoginDetails();
    resetOtpDetails();
    resetOtpVerification();
    resetPassword();
  };

  return {
    login,
    data,
    isLoading,
    otpDetails,
    sendOtp,
    sendOtpLoading,
    verifyOtp,
    verificationData,
    access_token,
    get_stream_token,
    userData,
    resetOtpDetails,
    resetLoginDetails,
    resetOtpVerification,
    verifyLoading,
    logoutUser,
    forgotPassword,
    forgotPassLoading,
    resetPass,
    resetPassLoading,
    resetAuthData,
    resetLogoutData,
  };
};
