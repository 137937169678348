export const PATHS = {
  // Default
  DEFAULT: "/",

  // Auth
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  OTP_DETAILS: "/otp-details",
  OTP_VERIFICATION: "/otp-verification",

  // Dashboard Module
  DASHBOARD: "/dashboard",

  // User management
  USER: "/users",
  USER_LIST: "user-list",
  ADMIN_USER: "admin-users",

  //Phrase Moderation
  PHRASE_MODERATION: "/phrase-moderation",

  //Tickets
  TICKETS: "/tickets",
  TICKETS_REASONS: "tickets-reasons",

  //Orders
  ORDERS: "/orders",

  //Commission
  COMMISSION: "/commission",

  // Content management
  CONTENT: "/content",
  STATIC_PAGES: "static-pages",

  // Master management
  MASTER_MANAGEMENT: "/master",
  COUNTRY: "country",
  TIMEZONE: "timezone",
  LANGUAGE: "language",

  // Support
  SUPPORT: "/support",

  // Settings
  SETTINGS: "/settings",
  GENERAL_SETTINGS: "general-settings",
  SOCIAL_MEDIA: "social-media",
  PAYMENT_GATEWAY: "payment-gateway",
  SMTP_SMS_DETAILS: "smtp-details",
  EMAIL_NOTIFICATIONS: "email-notifications",
  LANGUAGE_MESSAGES: "language/messages",
  LANGAUGE_STATIC_LANG: "language/static-label",

  // Common Pages
  FourZeroFour: "/common/404",
  THANKYOU: "/common/thankyou",
  COMING_SOON: "/common/coming-soon",

  // Profile
  MY_PROFILE: "/profile",
  EDIT_MY_PROFILE: "/profile/edit-profile",

  // Change Password
  CHANGE_PASSWORD: "/change-password",

  // Ui Kit
  UI_KIT: "/uikit",
  TYPOGRAPHY: "typography",
  COLORS: "colors",
  FORMS: "forms",
  CARDS: "cards",
  BLOCKS: "blocks",
  // Extras
  ANY: "*",
  VIEW: "view",
  CREATE: "create",
  EDIT: "edit",
};
