import * as types from "store/actions/index";

/** ***************** 
@purpose :  Handle user permission action to save token
@Parameter : {value, key}
@Author : INIC
******************/
export const perimssionsActions = (payload) => ({
  type: types.permissionsKey.toString(),
  payload,
});
