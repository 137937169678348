import { createApi } from "@reduxjs/toolkit/query/react";

import apiConfig from "configs/apiConfig";
import { REDUCER_PATHS } from "store/config";
import { transformResponseHandler } from "utils/transformResponseHandler";

import { customFetchBase } from ".";

const supportApi = createApi({
  reducerPath: REDUCER_PATHS.SUPPORT,
  baseQuery: customFetchBase,

  endpoints: (builder) => ({
    getSupportList: builder.query({
      query: (data) => ({
        url: `${apiConfig.SUPPORT}?page=${data?.page}&pageSize=${data?.pageSize}&searchText=${data?.searchText}&sortColumn=${data?.sortColumn}&sortDirection=${data?.sortDirection}&adminAction=${data?.adminAction}`,
        method: "GET",
      }),
      providesTags: ["getSupportListData"],
    }),
    deleteSupportListData: builder.mutation({
      query: (data) => ({
        url: apiConfig.SUPPORT,
        body: data,
        method: "DELETE",
      }),
      invalidatesTags: ["getSupportListData"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    getSupportListDetails: builder.query({
      query: (data) => ({
        url: `${apiConfig.GET_DETAIL_SUPPORT}/${data?.id}`,
        method: "GET",
      }),
      providesTags: ["getSupportListDetails"],
    }),
    changeTicketStatus: builder.mutation({
      query: (data) => ({
        url: apiConfig.SUPPORT,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["getSupportListData", "getSupportListDetails"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    addReplyData: builder.mutation({
      query: (data) => ({
        url: apiConfig.SUPPORT_REPLY,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["getSupportListDetails"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editReplyData: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.SUPPORT_REPLY}/${data?.replyId}`,
        body: data?.postData,
        method: "PUT",
      }),
      invalidatesTags: ["getSupportListDetails"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    deleteReply: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.SUPPORT_REPLY}/${data?.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["getSupportListDetails"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    addTicketReason: builder.mutation({
      query: (data) => ({
        url: apiConfig.ADD_TICKET_REASON,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["getSupportListDetails", "getSupportListData"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
  }),
});

export const {
  useLazyGetSupportListQuery,
  useDeleteSupportListDataMutation,
  useGetSupportListDetailsQuery,
  useAddTicketReasonMutation,
  useChangeTicketStatusMutation,
  useAddReplyDataMutation,
  useEditReplyDataMutation,
  useDeleteReplyMutation,
} = supportApi;

export default supportApi;
