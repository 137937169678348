import { createApi } from "@reduxjs/toolkit/query/react";

import apiConfig from "configs/apiConfig";
import { REDUCER_PATHS } from "store/config";
import { transformResponseHandler } from "utils/transformResponseHandler";

import { customFetchBase } from ".";

const phraseModerationApi = createApi({
  reducerPath: REDUCER_PATHS.PHRASE_MODERATION,
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    phraseModerationListing: builder.query({
      query: (data) => ({
        url: `${apiConfig.PHRASE_MODERATION}?page=${data?.page}&pageSize=${data?.pageSize}&searchText=${data?.searchText}&sortColumn=${data?.sortColumn}&sortDirection=${data?.sortDirection}`,
        method: "GET",
      }),
      providesTags: ["phraseModerationList"],
    }),
    phraseModerationDetail: builder.query({
      query: (data) => ({
        url: `${apiConfig.PHRASE_MODERATION_DETAIL}?phraseId=${data}`,
        method: "GET",
      }),
    }),
    addPhrase: builder.mutation({
      query: (data) => ({
        url: apiConfig.ADD_PHRASE,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["phraseModerationList"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editPhrase: builder.mutation({
      query: (data) => ({
        url: apiConfig.EDIT_PHRASE,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["phraseModerationList"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    deletePhrase: builder.mutation({
      query: (data) => ({
        url: apiConfig.DELETE_PHRASE,
        body: data,
        method: "DELETE",
      }),
      invalidatesTags: ["phraseModerationList"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    changePhraseStatus: builder.mutation({
      query: (data) => ({
        url: apiConfig.CHANGE_PHRASE_STATUS,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["phraseModerationList"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
  }),
});

export const {
  useLazyPhraseModerationListingQuery,
  useAddPhraseMutation,
  useEditPhraseMutation,
  useDeletePhraseMutation,
  useChangePhraseStatusMutation,
  useLazyPhraseModerationDetailQuery,
} = phraseModerationApi;

export default phraseModerationApi;
