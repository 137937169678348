import { createReducer } from "@reduxjs/toolkit";

import { permissionsKey } from "store/actions";

/** ***************** 
@purpose : Intital ui reducer data
@Author : INIC
***************** */
const initialState = {
  modules: [],
  formatedModulesData: {},
  isModuleLoading: true,
  slug: "",
  role: "",
  _id: "",
};

/** ***************** 
  @purpose : UI Reducer
  @Parameter : {ui_ini_data, action}
  @Author : INIC
  ***************** */
const permissionsReducer = createReducer(initialState, (builder) => {
  builder.addCase(permissionsKey.toString(), (state, action) => {
    state.modules = action.payload.modules;
    state.slug = action.payload.slug;
    state.role = action.payload.role;
    state._id = action.payload._id;
    state.formatedModulesData = action.payload.formatedModulesData;
    state.isModuleLoading = action.payload.isModuleLoading;
  });
});

export default permissionsReducer;
