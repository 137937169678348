import React from "react";
import Image from "react-bootstrap/Image";
import { Outlet } from "react-router-dom";

import logo from "assets/images/logo.svg";
import welcomeSvg from "assets/images/svg-images/welcome.svg";

function AuthLayout() {
  return (
    <>
      <div className="onboarding-page">
        {/* Onboard Welcome Image Start */}
        <div className="onboarding-page-item">
          <Image src={welcomeSvg} alt="welcome-onboard" className="img-fluid" />
        </div>
        {/* Onboard Welcome Image End */}

        <div className="onboarding-page-item">
          {/* Login Form Container Start */}

          <figure className="company-logo">
            <Image src={logo} alt="company-logo" className="img-fluid" />
          </figure>
          <div id="login-form" className="w-100">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}

export default AuthLayout;
