import { createApi } from "@reduxjs/toolkit/query/react";

import apiConfig from "configs/apiConfig";
import { REDUCER_PATHS } from "store/config";
import { transformResponseHandler } from "utils/transformResponseHandler";

import { customFetchBase } from ".";

const emailTemplatesApi = createApi({
  reducerPath: REDUCER_PATHS.EMAIL_TEMPLATES,
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    listing: builder.query({
      query: (data) => ({
        url: `${apiConfig.LISTING}?page=${data?.page}&pageSize=${data?.pageSize}&sortColumn=${data?.sortColumn}&sortDirection=${data?.sortDirection}&searchText=${data?.searchText}&languageId=${data?.languageId}`,
        method: "GET",
      }),
    }),
    emailTemplateStatusChange: builder.mutation({
      query: (data) => ({
        url: apiConfig.STATUS_CHANGE,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["emailTemplatedDetails"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    emailTemplateDelete: builder.mutation({
      query: (data) => ({
        url: apiConfig.EMAIL_TEMPLATES,
        body: data,
        method: "DELETE",
      }),
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    addEmailTemplate: builder.mutation({
      query: (data) => ({
        url: apiConfig.EMAIL_TEMPLATES,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["emailTemplatedDetails"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    emailTemplatesDetails: builder.query({
      query: (data) => ({
        url: `${apiConfig.EMAIL_TEMPLATES}?emailKey=${data?.emailKey}&languageId=${data?.languageId}`,
        method: "GET",
      }),
      providesTags: ["emailTemplatedDetails"],
    }),
    editEmailTemplate: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.EMAIL_TEMPLATES}/${data.id}`,
        body: data.postData,
        method: "PUT",
      }),
      invalidatesTags: ["emailTemplatedDetails"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
  }),
});

export const {
  useLazyListingQuery,
  useEmailTemplateStatusChangeMutation,
  useEmailTemplateDeleteMutation,
  useAddEmailTemplateMutation,
  useLazyEmailTemplatesDetailsQuery,
  useEditEmailTemplateMutation,
} = emailTemplatesApi;

export default emailTemplatesApi;
