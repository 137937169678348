import { createApi } from "@reduxjs/toolkit/query/react";

import apiConfig from "configs/apiConfig";
import { REDUCER_PATHS } from "store/config";
import { DEFAULT_LANGUAGE_CODE } from "utils/constants";
import { transformResponseHandler } from "utils/transformResponseHandler";

import { customFetchBase } from ".";

const blogApi = createApi({
  reducerPath: REDUCER_PATHS.BLOG,
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    staticManagementListing: builder.query({
      query: (data) => ({
        url: `${apiConfig.STATIC_PAGE_MANAGEMENT_LISTING}?page=${data?.page}&pageSize=${data?.pageSize}&searchText=${data?.searchText}&sortColumn=${data?.sortColumn}&sortDirection=${data?.sortDirection}&languageId=${data?.languageId}`,
        method: "GET",
      }),
      providesTags: ["staticPageListing"],
    }),
    staticManagementChangeStatus: builder.mutation({
      query: (data) => ({
        url: apiConfig.STATIC_PAGE_MANAGEMENT,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["staticPageListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    addStaticManagement: builder.mutation({
      query: (data) => ({
        url: apiConfig.STATIC_PAGE_MANAGEMENT,
        body: data?.postData,
        method: "POST",
      }),
      invalidatesTags: ["StaticPageDetails", "staticPageListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editStaticManagement: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.STATIC_PAGE_MANAGEMENT}/${data?.id}`,
        body: data?.postData,
        method: "PUT",
      }),
      invalidatesTags: ["StaticPageDetails", "staticPageListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    deleteStaticManagement: builder.mutation({
      query: (data) => ({
        url: apiConfig.STATIC_PAGE_MANAGEMENT,
        body: data,
        method: "DELETE",
      }),
      invalidatesTags: ["staticPageListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    staticManagementViewDetails: builder.query({
      query: (data) => ({
        url: `${apiConfig.STATIC_PAGE_MANAGEMENT}?staticCode=${data?.staticCode}&languageId=${data?.languageId}`,
        method: "GET",
      }),
      providesTags: ["StaticPageDetails"],
    }),
    staticPageManagementDownload: builder.mutation({
      query: (data) => ({
        headers: {
          language: data.language || DEFAULT_LANGUAGE_CODE,
        },
        url: `${apiConfig.STATIC_PAGE_MANAGEMENT_DOWNLOAD}/${data.type}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyStaticManagementListingQuery,
  useAddStaticManagementMutation,
  useEditStaticManagementMutation,
  useDeleteStaticManagementMutation,
  useLazyStaticManagementViewDetailsQuery,
  useStaticManagementChangeStatusMutation,
  useStaticPageManagementDownloadMutation,
} = blogApi;

export default blogApi;
