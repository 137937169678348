import configs from "configs";

export default {
  API_URL: configs.REACT_APP_SERVER_URL,
  FIlE_UPLOAD: `${configs.FILE_IMAGE_URL}`,
  FILE_DOWNLOAD: `${configs.FILE_DOWNLOAD_URL}`,
  GOOGLE_KEY: `${configs.GOOGLE_KEY}`,

  // CMS Api url
  PRIVACY_POLICY: "PRIVACY_POLICY",

  // Posts api url
  POSTS: "posts",

  // Auth api url
  LOGIN: "admin/login",

  SEND_OTP: "admin/send-otp",

  VERIFY_OTP: "admin/verify-otp",
  LOGOUT: "admin/logout",

  REFRESH_TOKEN: "admin/refresh-access-token",

  FORGOT_PW: "admin/forgot-password",

  RESET_PW: "admin/reset-password",

  GET_COLUMN_TABLE_SETTINGS: "admin/table-column-settings",

  // User profile url
  PROFILE: "admin/profile",
  EDIT_PROFILE: "admin/edit-profile",
  FILE_UPLOAD: "admin/image-file-upload",
  CHANGE_PASSWORD: "admin/change-password",
  GLOBAL_CONFIG: "admin/global-config",
  DELETE_PROFILE: "admin",

  //Phrase Moderation
  PHRASE_MODERATION: "/phrase-moderation/listing",
  PHRASE_MODERATION_DETAIL: "/phrase-moderation/detail",
  ADD_PHRASE: "/phrase-moderation",
  EDIT_PHRASE: "/phrase-moderation",
  DELETE_PHRASE: "/phrase-moderation",
  CHANGE_PHRASE_STATUS: "/phrase-moderation",

  //tickets
  TICKET_REASONS: "/support/ticket-reasons/listing",
  TICKET_REASON_DETAIL: "/support/ticket-reasons/detail",
  ADD_REASON: "/support/ticket-reasons",
  EDIT_REASON: "/support/ticket-reasons",
  DELETE_REASON: "/support/ticket-reasons",
  CHANGE_REASON_STATUS: "/support/ticket-reasons",

  //commission
  GET_COMMISSION: "admin/commission",
  EDIT_COMMISSION: "admin/commission",

  // Master => Timezone
  TIMEZONE: "master/timezone",
  TIMEZONE_LIST: "master/timezone/listing",
  // Master => Country
  COUNTRY: "master/country",
  CURRENCY_LIST: "master/currency",
  // Master => Language
  LANGUAGE: "master/language",
  GET_LANGUAGE: "master/language/listing",
  //  settings
  META_DATA: "settings/general/meta-data",
  SITE_SETTING: "settings/general/site-settings",
  DATE_TIME: "settings/general/date-time",
  ANALYTICAL_DATA: "settings/general/analytical-data",
  SITE_UN_MAINTENANCE: "settings/general/site-un-maintenance",
  SOCIAL_MEDIA_SDK: "settings/social-media/social-media-sdk",
  SOCIAL_MEDIA_LINK: "settings/social-media/social-media-link",
  CHANGE_STATUS: "settings/social-media/sdk",
  SMTP_DETAILS: "settings/smtp",
  SMS_DETAILS: "settings/sms",
  NOTIFICATION: "settings/email-settings/notifications",
  LANGUAGE_MESSAGES: "settings/language-preferences/messages",
  LANGUAGE_LABEL: "settings/language-preferences/label",
  ADD_EDIT_PAYMENT: "settings/payment-gateway",
  TWO_WAY_AUTHENTICATION: "settings/general/change-2fa-status",
  GET_TWO_WAY_AUTHENTICATION: "settings/general/get-2fa",
  //role
  GET_ALL_PERMISSSION: "role/get-all-permission",
  ROLE: "role",
  CHANGE_ROLE: "/role/change-role",
  ROLE_LISTING: "role/listing",
  USER_LIST: "role/user-list",

  //emailTemplates
  LISTING: "email-template/listing",
  STATUS_CHANGE: "email-template/change-status",
  EMAIL_TEMPLATES: "email-template",

  //users
  LISTING_ADMIN_MANAGEMENT: "admin-management",
  ADMIN_MANAGEMENT: "admin-management",
  LISTING_USER_MANAGEMENT: "user-management/listing",
  ADMIN_USERS_DOWNLOAD: "admin-management/download",
  USER_MANAGEMENT_DOWNLOAD: "user-management/download",
  USER_MANAGEMENT: "user-management",
  USER_DETAILS_DOWNLOAD: "user-management/download",
  TICKETS_DETAILS: "admin/user/support/ticket",
  REVIEW_RATING_DETAILS: "admin/ratings",
  EDIT_REVIEW_RATING_DETAILS: "admin/ratings",
  DELETE_REVIEW: "admin/ratings",

  //Static page management
  STATIC_PAGE_MANAGEMENT_LISTING: "static-page/listing",
  STATIC_PAGE_MANAGEMENT: "static-page",
  STATIC_PAGE_MANAGEMENT_DOWNLOAD: "staticPage/download",

  //Commom Filter API
  SAVE_FILTER: "common/filter",
  SAVE_COLUMN: "common/column",

  //order
  ORDER: "admin/order/listing",
  ORDER_DETAIL: "admin/order/detail",
  ORDER_DETAILS_DOWNLOAD: "admin/order/download",

  //Support
  SUPPORT: "admin/support/ticket/listing",
  GET_DETAIL_SUPPORT: "admin/support/ticket/detail",
  ADD_TICKET_REASON: "admin/support/reply",
  SUPPORT_REPLY: "support/reply",

  //Dashboard
  GET_DASHBOARD_COUNT: "admin/dashboard",
};
