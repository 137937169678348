import {
  useAddLanguageListMutation,
  useGetLanguageQuery,
  useLanguageListChangeStatusMutation,
  useLanguageListDeleteMutation,
  useLazyLanguageListQuery,
} from "store/api/master";
import { fixedCacheKeys } from "utils/fixedCacheKeys";

export const useLanguageHook = () => {
  /** ***************** 
  @purpose : Used For handle get and reset data
  @Parameter : {data,reset}
  @Author : INIC
  ***************** */

  const { data: languageData } = useGetLanguageQuery();

  const [
    languageList,
    { data: languageListData, isUninitialized: isLanguageListUninitialized },
  ] = useLazyLanguageListQuery();

  const [languageListChangeStatus] = useLanguageListChangeStatusMutation({
    fixedCacheKey: fixedCacheKeys.LANGUAGE_LIST_CHANGE_STATUS,
  });

  const [languageListDelete] = useLanguageListDeleteMutation({
    fixedCacheKey: fixedCacheKeys.COUNTRY_LIST_DELETE,
  });

  const [addLanguageList, { isLoading: addLanguageListLoader }] =
    useAddLanguageListMutation({
      fixedCacheKey: fixedCacheKeys.ADD_COUNTRYLIST,
    });

  return {
    languageList,
    languageData,
    languageListData,
    languageListChangeStatus,
    languageListDelete,
    addLanguageList,
    addLanguageListLoader,
    isLanguageListUninitialized,
  };
};
